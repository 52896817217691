import React from "react";
// import { toastr } from "react-redux-toastr";
import { Link } from "react-router-dom";
import axios from "axios";
import { Col, Input, Card, CardBody } from "reactstrap";

/*Input Unit */

export const InputUnit = (props) => {
  return (
    <Input
      className="form-control inputSize "
      id={props.name}
      name={props.name}
      type={props.type}
      value={props.value}
      disabled={props.disabled ? props.disabled : false}
      min={props.min ? props.min : props.type === "number" ? 0 : null}
      max={props.max ? props.max : null}
      step={props.step ? props.step : props.type === "number" ? 1 : null}
      onChange={props.handleChange}
      placeholder={props.placeholder}
    />
  );
};

export const InputChecked = (props) => {
  return (
    <Input
      className="form-check-input"
      id={props.name}
      name={props.name}
      type={props.type}
      value={props.value}
      checked={props.checked}
      onChange={props.handleChange}
      placeholder={props.placeholder}
    />
  );
};
/*Input */
export const InputField = (props) => <InputUnit {...props} />;

/*Input Group */
export const InputGroupComp = (props) => {
  return (
    <Col md={6} lg={6} sm={12}>
      {props.title ? (
        <label htmlFor={props.name} className="control-label col-form-label-sm">
          {props.title}
        </label>
      ) : (
        ""
      )}
    </Col>
  );
};

/*Select.jsx*/

export const Select = (props) => {
  return (
    <select
      className="form-control"
      name={props.name}
      value={props.value}
      onChange={props.handleChange}
    >
      <option value="">{props.placeholder}</option>
      {props.options.map((option) => {
        return (
          <option key={option[props.index_key]} value={option[props.index_key]}>
            {get_labels(option, props.label_key)}
          </option>
        );
      })}
    </select>
  );
};
export const get_labels = (option_obj, keys) => {
  let labels = "";
  if (Array.isArray(keys)) {
    keys.forEach((key) => {
      labels += option_obj[key] + " ";
    });
  } else {
    labels = option_obj[keys];
  }
  return labels;
};
/* CheckBox.jsx */

export const CheckBox = (props) => {
  return (
    <Input
      className="form-check-input"
      id={props.id}
      name={props.name}
      value={props.value}
      checked={props.selectedOptions.indexOf(props.value) > -1}
      onChange={props.handleChange}
      type="checkbox"
    />
  );
};
export const CheckBoxGroup = (props) => {
  return (
    <div>
      {/* {props.title?<p className="control-label mt-3 col-form-label-sm">{props.title}</p>:''} */}
      <div className="checkbox-group">
        {props.options.map((option) => {
          return (
            <div
              className="form-check form-check-inline"
              key={option[props.index_key]}
            >
              <CheckBox {...props} value={option[props.index_key]} />
              <label className="form-check-label">
                {" "}
                {option[props.label_key]}
              </label>
            </div>
          );
        })}
      </div>
    </div>
  );
};
export const CheckBoxGroup2 = (props) => {
  return (
    <div>
      {props.title ? (
        <p className="control-label col-form-label-sm">{props.title}</p>
      ) : (
        ""
      )}
      <div className="checkbox-group">
        {props.options.map((option, idx) => {
          const handleChange = props.handleChange(idx);
          return (
            <div
              className="form-check form-check-inline"
              key={option[props.index_key]}
            >
              <CheckBox
                {...props}
                name={props.name + idx}
                handleChange={handleChange}
                value={option[props.index_key]}
              />
              <label className="form-check-label">
                {" "}
                {option[props.label_key]}
              </label>
            </div>
          );
        })}
      </div>
    </div>
  );
};
export const RadioGroup2 = (props) => {
  return (
    <div>
      {props.title ? (
        <p className="control-label col-form-label-sm">{props.title}</p>
      ) : (
        ""
      )}
      <div className="checkbox-group">
        {props.options.map((option, idx) => {
          const handleChange = props.handleChange(idx);
          return (
            <div
              className="form-check form-check-inline"
              key={option[props.index_key]}
            >
              <InputChecked
                {...props}
                name={props.name + idx}
                handleChange={handleChange}
                value={option[props.index_key]}
                type="radio"
                checked={
                  props.selectedOptions.indexOf(option[props.index_key]) > -1
                }
              />
              <label className="form-check-label">
                {option[props.label_key]}
              </label>
            </div>
          );
        })}
      </div>
    </div>
  );
};
export const RadioGroup = (props) => {
  return (
    <div>
      <div className="checkbox-group">
        {props.options.map((option) => {
          return (
            <div
              class="form-check form-check-inline"
              key={option[props.index_key]}
            >
              <InputChecked
                {...props}
                value={option[props.index_key]}
                type="radio"
                checked={
                  props.selectedOptions.indexOf(option[props.index_key]) > -1
                }
              />
              <label className="form-check-label">
                {option[props.label_key]}
              </label>
            </div>
          );
        })}
      </div>
    </div>
  );
};

/*TextArea.jsx */
export const TextArea = (props) => {
  return (
    <textarea
      className="form-control no-resize"
      id={props.name}
      name={props.name}
      value={props.value}
      cols={props.cols}
      rows={props.rows}
      onChange={props.handleChange}
      placeholder={props.placeholder}
    />
  );
};

export const ActionMore = (props) => {
  const { clickedIcon, id, deleteHandler, link_url, data_item, editHandler } =
    props;
  return (
    <>
      <div
        style={{
          position: "absolute",
        }}
      >
        <Card
          style={{
            display: clickedIcon !== id ? "none" : "",
          }}
        >
          <CardBody className="p-1">
            <ul className="iconStyles mb-0">
              {editHandler && (
                <li
                  onClick={(e) => editHandler(data_item)}
                  className="pointer-cursor"
                >
                  {/* <Edit fontSize="small" color="primary" /> */}
                  <span className="icon-list-style">Edit</span>
                </li>
              )}
              {link_url && (
                <Link
                  title="View Details"
                  to={
                    props.profile
                      ? { pathname: link_url }
                      : { pathname: link_url + data_item.id }
                  }
                  key={data_item.id}
                >
                  <li>
                    {/* <Visibility color="action" fontSize="small" /> */}
                    <span className="icon-list-style">View</span>
                  </li>
                </Link>
              )}
              {deleteHandler && (
                <li
                  className="pointer-cursor"
                >
                  {/* <Delete style={{ color: "red" }} fontSize="small" /> */}
                  <span className="icon-list-style">Delete</span>
                </li>
              )}
            </ul>
          </CardBody>
        </Card>
      </div>
    </>
  );
};

export const Actions = (props) => {
  const { editHandler, data_item, link_url, modal_id, deleteHandler } = props;
  return (
    <>
      {link_url && (
        <Link
          title="View Details"
          to={
            props.profile
              ? { pathname: link_url }
              : { pathname: link_url + data_item.id }
          }
          key={data_item.id}
        >
          <span className="badge badge-info  btn badge-btn">
            <img
              src="/images/view.svg"
              height="15px"
              width="15px"
              alt="view"
              className="ms-1"
            />
          </span>
        </Link>
      )}
      {editHandler && (
        <a
          title="Edit"
          href={"#" + modal_id}
          data-toggle="modal"
          onClick={(e) => editHandler(data_item)}
        >
          <span className="badge badge-warning mx-1 btn badge-btn">
            <img
              src="/images/edit.svg"
              height="15px"
              width="15px"
              className="ms-1"
              alt="edit"
            />
          </span>
        </a>
      )}
      {deleteHandler && (
        <a
          href={"#!"}
          title="Delete"
        >
          <span className="badge badge-danger px-1 btn badge-btn">
            <img
              src="/images/deleted.svg"
              height="15px"
              width="15px"
              alt="delete"
              className="ms-1"
            />
          </span>
        </a>
      )}
    </>
  );
};

export const app_url = "https://t1.eonpayafrica.com/";
export const apiurl = `${app_url}api/`;

export const headers = {
  "Content-Type": "application/json",
  "Access-Control-Allow-Origin": "*",
  //"Authorization": `Bearer ${localStorage.getItem('token')}`
};

export const base_url = (url) => {
  return apiurl + url;
};

export const auth_url = (url) => {
  return app_url + url;
};

export const allconstants = {
  api: app_url,
  apiurl,
  axios,
  headers,
};
