import React from 'react'
import { Switch, Route } from 'react-router-dom';
import {authRoutes} from '../../routes'
import Login from './login';

export default function AuthRoutes(props){
    return<Switch>
            {authRoutes.map((page,key)=><Route exact={typeof page.exact !== 'undefined'?page.exact:false} path={page.path} component={page.component} key={key} />)}
        <Route component={Login}/>
        </Switch>
}