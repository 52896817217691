import React from "react";
import { Row, Col } from "reactstrap";

export const WithdrawHistory = () => {
  return (
    <>
      <div className="py-3 ">
        <span className="recent-trans mb-1">Withdraw Logs</span>
        <span className="sort-table float-end">Sort results by</span>
      </div>
      <Row>
        <Col md={12} lg={12} sm={12}>
          <div className="content-box-header panel-heading table-container">
            <table className="table">
              <thead>
                <tr>
                  <th>Date</th>
                  <th>User</th>
                  <th>Phone</th>
                  <th>Status</th>
                  <th>Transaction ID</th>
                  <th>Amount</th>
                  <th>Time</th>
                </tr>
              </thead>
              <tbody>
                <tr className="trans-tbl-row mb-1">
                  <td className="trans-tbl-data">Fri, 26 Jul</td>
                  <td className="trans-tbl-data">Ali Baker</td>
                  <td className="trans-tbl-data">0753114565</td>
                  <td className="trans-tbl-data">
                    <span className="badge badge-info-light">PENDING</span>
                  </td>
                  <td className="trans-tbl-data">TXD 76899990</td>
                  <td className="trans-tbl-data">1000000</td>
                  <td className="trans-tbl-data">1:00 PM</td>
                </tr>
                <tr className="trans-tbl-row mb-1">
                  <td className="trans-tbl-data">Fri, 26 Jul</td>
                  <td className="trans-tbl-data">Ali Baker</td>
                  <td className="trans-tbl-data">0753114565</td>
                  <td className="trans-tbl-data">
                    <span className="badge badge-info-light">PENDING</span>
                  </td>
                  <td className="trans-tbl-data">TXD 76899990</td>
                  <td className="trans-tbl-data">1000000</td>
                  <td className="trans-tbl-data">1:00 PM</td>
                </tr>
                <tr className="trans-tbl-row mb-1">
                  <td className="trans-tbl-data">Fri, 26 Jul</td>
                  <td className="trans-tbl-data">Ali Baker</td>
                  <td className="trans-tbl-data">0753114565</td>
                  <td className="trans-tbl-data">
                    <span className="badge badge-success-light">COMPLETED</span>
                  </td>
                  <td className="trans-tbl-data">TXD 76899990</td>
                  <td className="trans-tbl-data">1000000</td>
                  <td className="trans-tbl-data">1:00 PM</td>
                </tr>
                <tr className="trans-tbl-row mb-1">
                  <td className="trans-tbl-data">Fri, 26 Jul</td>
                  <td className="trans-tbl-data">Ali Baker</td>
                  <td className="trans-tbl-data">0753114565</td>
                  <td className="trans-tbl-data">
                    <span className="badge badge-success-light">COMPLETED</span>
                  </td>
                  <td className="trans-tbl-data">TXD 76899990</td>
                  <td className="trans-tbl-data">1000000</td>
                  <td className="trans-tbl-data">1:00 PM</td>
                </tr>
                <tr className="trans-tbl-row mb-1">
                  <td className="trans-tbl-data">Fri, 26 Jul</td>
                  <td className="trans-tbl-data">Ali Baker</td>
                  <td className="trans-tbl-data">0753114565</td>
                  <td className="trans-tbl-data">
                    <span className="badge badge-danger-light">CANCELLED</span>
                  </td>
                  <td className="trans-tbl-data">TXD 76899990</td>
                  <td className="trans-tbl-data">1000000</td>
                  <td className="trans-tbl-data">1:00 PM</td>
                </tr>
                <tr className="trans-tbl-row mb-1">
                  <td className="trans-tbl-data">Fri, 26 Jul</td>
                  <td className="trans-tbl-data">Ali Baker</td>
                  <td className="trans-tbl-data">0753114565</td>
                  <td className="trans-tbl-data">
                    <span className="badge badge-warning-light">FAILED</span>
                  </td>
                  <td className="trans-tbl-data">TXD 76899990</td>
                  <td className="trans-tbl-data">1000000</td>
                  <td className="trans-tbl-data">1:00 PM</td>
                </tr>
                <tr className="trans-tbl-row mb-1">
                  <td className="trans-tbl-data">Fri, 26 Jul</td>
                  <td className="trans-tbl-data">Ali Baker</td>
                  <td className="trans-tbl-data">0753114565</td>
                  <td className="trans-tbl-data">
                    <span className="badge badge-warning-light">FAILED</span>
                  </td>
                  <td className="trans-tbl-data">TXD 76899990</td>
                  <td className="trans-tbl-data">1000000</td>
                  <td className="trans-tbl-data">1:00 PM</td>
                </tr>
                <tr className="trans-tbl-row mb-1">
                  <td className="trans-tbl-data">Fri, 26 Jul</td>
                  <td className="trans-tbl-data">Ali Baker</td>
                  <td className="trans-tbl-data">0753114565</td>
                  <td className="trans-tbl-data">
                    <span className="badge badge-warning-light">FAILED</span>
                  </td>
                  <td className="trans-tbl-data">TXD 76899990</td>
                  <td className="trans-tbl-data">1000000</td>
                  <td className="trans-tbl-data">1:00 PM</td>
                </tr>
                <tr className="trans-tbl-row mb-1">
                  <td className="trans-tbl-data">Fri, 26 Jul</td>
                  <td className="trans-tbl-data">Ali Baker</td>
                  <td className="trans-tbl-data">0753114565</td>
                  <td className="trans-tbl-data">
                    <span className="badge badge-danger-light">CANCELLED</span>
                  </td>
                  <td className="trans-tbl-data">TXD 76899990</td>
                  <td className="trans-tbl-data">1000000</td>
                  <td className="trans-tbl-data">1:00 PM</td>
                </tr>
              </tbody>
            </table>
          </div>
        </Col>
      </Row>
    </>
  );
};
