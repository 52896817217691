import React from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { currFormat } from "../../../constants/GenHelpers";

const DashDonut = ({ collctn, payment }) => {
  const options = {
    title: {
      text: "",
    },
    exporting: {
      enabled: true,
    },
    credits: {
      enabled: false,
    },
    legend: {
      enabled: false,
    },
    tooltip: {
      enabled: false,
    },
    chart: {
      plotBackgroundColor: null,
      plotBorderWidth: 0,
      plotShadow: false,
    },

    plotOptions: {
      pie: {
        borderWidth: 0,
        dataLabels: {
          enabled: true,
          color: "rgba(0,0,0,0.87)",
          style: {
            fontWeight: "normal",
            textOutline: "none",
          },
          connectorWidth: 0,
          distance: 10,
          formatter() {
            return `${currFormat(this.y)}<br> ${this.point.name}`;
          },
        },
        size: 200,
      },
    },
    series: [
      {
        type: "pie",
        innerSize: "50%",
        data: [
          {
            name: "Collections",
            color: "#3399cc",
            y: collctn,
          },
          {
            name: "Payments",
            color: "#ff6600",
            y: payment,
          },
        ],
      },
    ],
  };
  return (
    <div>
      <HighchartsReact highcharts={Highcharts} options={options} />
    </div>
  );
};

export default DashDonut;
