import React from "react";
import { Formik, Form } from "formik";
import { TextField } from "../../auth/TextField";
import { timeFormat } from "../../../constants/GenHelpers";
import * as Yup from "yup";
import {
  Modal,
  ModalHeader,
  ModalBody,
  Row,
  FormGroup,
  Col,
  Button,
  Label,
} from "reactstrap";
import { useSelector } from "react-redux";

export const AddLink = (props) => {
  const { current_pay_req_obj, isPRProgess } = useSelector(
    (state) => state.PayReqState
  );
  const { submitHandler, toggle, modal } = props;

  const validate = Yup.object({
    amount: Yup.number().required("Amount is required"),
    purpose: Yup.string().required("Purpose is required"),
    start_time: Yup.string().required("Start time is required"),
    end_time: Yup.string().required("End time is required"),
    max_amount: Yup.number(),
    min_amount: Yup.number(),
  });

  return (
    <>
      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>
          <span>Request Details</span>
        </ModalHeader>
        <ModalBody>
          <Formik
            initialValues={{
              amount: current_pay_req_obj?.amount ?? "",
              start_time: timeFormat(current_pay_req_obj?.start_time) ?? "",
              end_time: timeFormat(current_pay_req_obj?.end_time) ?? "",
              purpose: current_pay_req_obj?.purpose ?? "",
              min_amount: current_pay_req_obj?.min_amount ?? "",
              max_amount: current_pay_req_obj?.max_amount ?? "",
              id: current_pay_req_obj?.req_id ?? "",
            }}
            validationSchema={validate}
            onSubmit={(values, e) => {
              submitHandler(
                values,
                props.comp_obj.default_company_account.id,
                e,
                toggle
              );
            }}
          >
            {(formik) => (
              <Form>
                <FormGroup>
                  <Row className="mt-3">
                    <div className="container-fluid">
                      <Row>
                        <Col lg={12} md={12} sm={12} className="my-2">
                          <TextField
                            name="purpose"
                            type="text"
                            placeholder="Payment purpose"
                          />
                        </Col>{" "}
                      </Row>
                      <Row className="mt-3">
                        <Col lg={12} md={12} sm={12} className="my-2">
                          <TextField
                            name="amount"
                            type="text"
                            placeholder="Amount"
                          />
                        </Col>{" "}
                        <Row className="pl-3 pr-3 mt-3">
                          <Col lg={6} sm={12} md={12}>
                            <TextField
                              name="min_amount"
                              type="number"
                              placeholder="Min Amount"
                            />
                          </Col>
                          <Col lg={6} sm={12} md={12}>
                            <TextField
                              name="max_amount"
                              type="number"
                              placeholder="Max Amount"
                            />
                          </Col>
                        </Row>
                      </Row>
                      <Row>
                        <Col lg={6} sm={12} md={12}>
                          <Label className="label-text">Activation date </Label>

                          <TextField name="start_time" type="datetime-local" />
                        </Col>
                        <Col lg={6} sm={12} md={12}>
                          <Label className="label-text">Expiry date </Label>
                          <FormGroup>
                            <TextField name="end_time" type="datetime-local" />
                          </FormGroup>
                        </Col>
                      </Row>
                    </div>
                  </Row>
                </FormGroup>

                <Button
                  type="submit"
                  disabled={isPRProgess}
                  className="pay-btn"
                  block
                >
                  <span className="pay-btn-text">
                    {current_pay_req_obj.req_id != null
                      ? isPRProgess
                        ? "Updating ..."
                        : "Update"
                      : isPRProgess
                      ? "Saving ..."
                      : "Save"}
                  </span>
                </Button>
              </Form>
            )}
          </Formik>
        </ModalBody>
      </Modal>
    </>
  );
};
