import React, { useState, useRef } from "react";
import { Link, Redirect } from "react-router-dom";
import { base_url } from "../../constants/constants";
import Axios from "axios";
import { Row, Col, Button } from "reactstrap";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import WelcomeText from "./welcomeText";
import { TextField } from "./TextField";

const ResetPass = (props) => {
  //const uri = props.location.pathname.split("/");
  const PASSWORD_REGEX = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?).{8,}$/;
  const [validateAfterSubmit, setValidateAfterSubmit] = useState(false);
  const formikRef = useRef();
  const resetToken = props.match.params.token;
  const [is_loading, setIsLoading] = useState(false);

  const [redirectUser, redirectTo] = useState(false);

  const validate = Yup.object({
    email: Yup.string().email("Email is invalid").required("Email is required"),
    password: Yup.string()
      .min(8, "Password must be at least 8 charaters")
      .matches(PASSWORD_REGEX, "Please Enter a strong password")
      .required("Password is required"),
    password_confirmation: Yup.string()
      .oneOf([Yup.ref("password"), null], "Passwords must match")
      .required("Password confirmation is required"),
  });

  const sendNewPwd = (e, setErrors, setSubmitting) => {
    setIsLoading(true);
    const url = base_url("merchant/reset-password");
    Axios.post(
      url,
      {
        email: e.email,
        password: e.password,
        password_confirmation: e.password_confirmation,
        token: resetToken,
      },
      {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      }
    )
      .then((res) => {
        if (res.status === 200 || res.status === 201) {
          // toastr.success("Your password has been reset successfully");
          redirectTo(true);
          setIsLoading(false);
        } else {
          // toastr.error("Opps something went wrong, try again");
          setIsLoading(false);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        if (error.response) {
          setSubmitting(false);
          setErrors(error.response.data.errors);
        } else if (error.request) {
          console.log(error.request);
        } else {
          // toastr.error("Please check your network and try again");
          console.log("Bad/No internet connection");
        }
      });
  };
  if (redirectUser === true) {
    return (
      <Redirect
        to={{
          pathname: "/login",
        }}
      />
    );
  }
  return (
    <>
      <div
        className="login-body container-fluid"
        style={{ height: "100vh", overflowY: "hidden" }}
      >
        <Row>
          <Col lg={9} md={9} sm={12}>
            <img
              width="800px"
              height="200px"
              src="../../images/login-svg.png"
              alt=""
            />
          </Col>
          <Col lg={3} md={3} sm={12}>
            <nav className="navbar navbar-expand-lg navbar-light">
              <Button
                className="navbar-toggler"
                type="button"
                data-toggle="collapse"
                data-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span className="navbar-toggler-icon" />
              </Button>
              <div
                className="collapse navbar-collapse"
                id="navbarSupportedContent"
              >
                <ul className="navbar-nav me-auto">
                  <li className="nav-item active">
                    <Link className="nav-link login-nav-btn" to="/">
                      Login <span className="sr-only"></span>
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link login-nav-btn" to="/faq">
                      FAQ
                    </Link>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link login-nav-btn" href="/#">
                      Help &amp; Contact
                    </a>
                  </li>
                  <li className="nav-item dropdown">
                    <a
                      className="nav-link dropdown-toggle login-nav-btn"
                      href="/#"
                      id="navbarDropdown"
                      role="button"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      ENG
                    </a>
                  </li>
                </ul>
              </div>
            </nav>
          </Col>
        </Row>
        <Row>
          <Col lg={7} md={6} sm={12} className="pt-5 px-5 text-center">
            <WelcomeText
              welcome="Welcome Back to"
              description="Always keep your account information and security details safe"
              shortDescription="to avoid any inconveniences."
            />
            <h5 className="mx-auto text-center">Password Reset</h5>
          </Col>

          <Col lg={5} md={5} sm={12}>
            <div
              className="login-wrapper"
              style={{ marginRight: 200, paddingBottom: 50 }}
            >
              <div className="box login-dialog-box">
                <div className="content-wrap">
                  <Formik
                    enableReinitialize
                    initialValues={{
                      email: "",
                      password: "",
                      password_confirmation: "",
                    }}
                    validationSchema={validate}
                    validateOnChange={validateAfterSubmit}
                    validateOnBlur={true}
                    ref={formikRef}
                    onSubmit={(values, { setErrors, setSubmitting }) => {
                      setValidateAfterSubmit(true);
                      props.dispatch(
                        sendNewPwd(values, setErrors, setSubmitting)
                      );
                    }}
                  >
                    {(formik) => (
                      <div>
                        <Form className="border border-light p-3">
                          <h4 className="mb-1 pass-reset  ml-3">
                            Password Reset
                          </h4>
                          <span className="pass-reset-text mb-2">
                            Create a new password
                          </span>
                          <div className="my-2">
                            <TextField
                              name="email"
                              type="email"
                              placeholder="E-mail"
                            />
                            <TextField
                              label="password"
                              name="password"
                              type="password"
                              placeholder="Password"
                            />
                            <TextField
                              label="Confirm Password"
                              name="password_confirmation"
                              type="password"
                              placeholder="ConfirmPassword"
                            />
                          </div>

                          {is_loading ? (
                            <>
                              <Button
                                className="sign-in-btn  shadow-none text-white"
                                block
                              >
                                <div
                                  className="spinner-grow"
                                  role="status"
                                ></div>
                                Processing...
                              </Button>{" "}
                            </>
                          ) : (
                            <>
                              <Button
                                className="sign-in-btn  my-1 text-white shadow-none"
                                block
                                type="submit"
                              >
                                Submit
                              </Button>
                            </>
                          )}
                          <div className="text-center">
                            <div className="already">
                              <p className="sign-up-text">
                                Don't have an account yet?
                              </p>
                              <Link to="/signup" className="sign-up-text">
                                Get started
                              </Link>
                            </div>
                          </div>
                        </Form>
                      </div>
                    )}
                  </Formik>
                </div>
              </div>
            </div>
          </Col>
        </Row>
        <Row style={{ margin: 200, height: "100vh" }}>
          <Col lg={7} md={7} sm={12}>
            <div className>
              <span className="privacy-text">Privacy Policy</span>
              <span className="privacy-text">Terms &amp; Conditions</span>
            </div>
          </Col>
          <Col lg={5} md={5} sm={12}>
            <span className="privacy-text">
              {" "}
              All Rights Reserved. GMT Consults {new Date().getFullYear()}
            </span>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default ResetPass;
