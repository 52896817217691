import React from "react";
import { Switch, Route, withRouter } from "react-router-dom";
import routes from "../../routes";
import DashboardHome from "./dashboard";
import { TopNav } from "./header";
import { FootRightSide } from "./footer";
import { SideBar } from "./sidebar";
import "../../assets/styles/global.css";
import { connect } from "react-redux";
import WelcomePage from "./new_user";
import SessionTimeoutPrompt from "../auth/sessionTimeoutPrompt";
import { Col, Row } from "reactstrap";

const MainAppRoutes = (props) => {
  const default_company_exists = props.session_data.active_company != null; //session data should be checked elsewhere
  return (
    <>
      <>
        <TopNav
          default_company={default_company_exists}
          dispatch={props.dispatch}
          session_data={props.session_data}
          location_obj={props.location}
          company_list={props.company_list}
        />
        <div className="page-content">
          <Row>
            <SideBar
              session_data={props.session_data}
              location_obj={props.location}
            />
            <Col md={9} sm={12} lg={10} className="mb-5">
              <SessionTimeoutPrompt {...props} />
              <Switch>
                {routes.map((page, key) => (
                  <Route
                    exact={
                      typeof page.exact !== "undefined" ? page.exact : false
                    }
                    path={page.path}
                    component={page.component}
                    key={key}
                  />
                ))}
                <Route path="/welcome" component={() => <WelcomePage />} />
                <Route component={DashboardHome} />
              </Switch>
            </Col>
          </Row>
        </div>
        <div id="footer-back" className="mt-2">
          <FootRightSide />
        </div>
      </>
    </>
  );
};
// connect the store for the company dispatches and state
const mapAppStateToProps = (state) => {
  return {
    session_data: state.authState.user_obj,
    company_list: state.companyState.company_list,
    single_record: state.companyState.single_record,
    expiryTime: state.authState.exp_time,
  };
};

const MainAppReduxComp = withRouter(connect(mapAppStateToProps)(MainAppRoutes));
export default MainAppReduxComp;
