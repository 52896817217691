import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { HandleCompanyFetch } from "../../../../actions/companyActions";
import { Col, Row, Card, CardHeader, CardBody } from "reactstrap";

const CompanyProfile = ({ match, company_details,dispatch }) => {
  const {params} = match;
  React.useEffect(() => {
    (typeof company_details === "undefined" ||
      typeof company_details.id === "undefined") &&
      dispatch(HandleCompanyFetch(params.id, true));
  }, [dispatch,company_details,params]);

  return (
    <Card>
      <CardHeader>
        <span className="account-title mb-3">Business Details</span>
      </CardHeader>
      <CardBody>
        <div className="d-flex justify-content-between ">
          <span>
            <h6 className="text-uppercase mt-2">
              {company_details.company_name}
            </h6>
          </span>
          <span>
            <h6 className="text-uppercase mt-2">
              {typeof company_details.acc_balance !== "undefined" &&
                company_details.acc_balance &&
                "Wallet(UGX):" + company_details.acc_balance.acc_bal}
            </h6>
          </span>
        </div>
        <Row>
          <Col lg={6} md={6} sm={6}>
            <span className="title-header d-block">Company Name</span>
            <span className="title-text">
              {company_details.company_name
                ? company_details.company_name
                : "LOADING..."}
            </span>
          </Col>
          <Col lg={6} md={6} sm={6}>
            <span className="title-header d-block">Company Type</span>
            <span className="title-text">
              {company_details.company_type
                ? company_details.company_type
                : "LOADING..."}
            </span>
          </Col>
        </Row>
        <Row className="mt-1">
          <Col lg={6} md={6} sm={6}>
            <span className="title-header d-block">Company Address</span>
            <span className="title-text">
              {company_details.address ? company_details.address : "LOADING..."}
            </span>
          </Col>
          <Col lg={6} md={6} sm={6}>
            <span className="title-header d-block">Status</span>
            <span className="title-text-blue">
              {company_details.status
                ? company_details.status_text
                : "LOADING..."}
            </span>
          </Col>
        </Row>
        <Row className="mt-1">
          <Col lg={6} md={6} sm={6}>
            <span className="title-header d-block">Description</span>
            <span className="title-text">
              {company_details.description
                ? company_details.description
                : "LOADING..."}
            </span>
          </Col>

          <Col lg={6} md={6} sm={6}>
            <span className="title-header d-block">No. of Employees</span>
            <span className="title-text">
              {company_details.no_of_employees
                ? company_details.o_of_employees
                : "LOADING..."}
            </span>
          </Col>
        </Row>
        <Row className="mt-1">
          <Col lg={6} md={6} sm={6}>
            <span className="title-header d-block">Decision by</span>
            <span className="title-text">
              {" "}
              {company_details.decision_by
                ? company_details.decision_by
                : "LOADING..."}
            </span>
          </Col>
          <Col lg={6} md={6} sm={6}>
            <span className="title-header d-block">Reason for Decision</span>
            <span className="title-text">
              {" "}
              {company_details.reason_for_decision
                ? company_details.reason_for_decision
                : "LOADING..."}
            </span>
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
};

const mapCompanyDetailsStateToProps = (state) => {
  return {
    company_details: state.companyState.single_record,
    current_company_obj: state.companyState.current_company_obj,
    company_list: state.companyState.company_list,
  };
};
export const CompanyProfileReduxComp = withRouter(
  connect(mapCompanyDetailsStateToProps)(CompanyProfile)
);
