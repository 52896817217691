import React from "react";
import PaginationStyles from "./assets/css/pagination.module.css";
import {
  useTable,
  useFilters,
  useGlobalFilter,
  useAsyncDebounce,
  usePagination,
  useSortBy,
} from "react-table";
import { matchSorter } from "match-sorter";
//import ReactHTMLTableToExcel from "@goodev/react-html-table-to-excel";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { Button, Row, Col } from "reactstrap";
const doc = new jsPDF();
// Define a default UI for filtering
function GlobalFilter({
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
}) {
  const count = preGlobalFilteredRows.length;
  const [value, setValue] = React.useState(globalFilter);
  const onChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 200);
  const download = () => {
    doc.autoTable({ html: "#table-to-xls" });
    doc.save("table.pdf");
    window.location.reload();
  };
  return (
    <>
      <Row className="my-3">
        <Col>
          <span
            className="ms-1 btn btn-primary  float-start"
            onClick={() => download()}
          >
            PDF
          </span>
        </Col>
        {/*<ReactHTMLTableToExcel
        id="test-table-xls-button"
        className="download-table-xls-button ml-1 btn btn-primary"
        table="table-to-xls"
        filename="Report"
        sheet="Report"
        buttonText="Excel"
      />*/}
        <Col>
          <span className="search_text">
            <input
              autoComplete="off"
              className="form-control float-end"
              style={{
                border: "none",
                borderBottom: "1px solid #4380F9",
                width: "250px",
              }}
              value={value || ""}
              onChange={(e) => {
                setValue(e.target.value);
                onChange(e.target.value);
              }}
              placeholder={`Search ${count} records...`}
            />
          </span>
        </Col>
      </Row>
    </>
  );
}

// Define a default UI for filtering
function DefaultColumnFilter({
  column: { filterValue, preFilteredRows, setFilter },
}) {
  const count = preFilteredRows.length;

  return (
    <input
      autoComplete="off"
      value={filterValue || ""}
      onChange={(e) => {
        setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
      }}
      placeholder={`Search ${count} records...`}
    />
  );
}

function fuzzyTextFilterFn(rows, id, filterValue) {
  return matchSorter(rows, filterValue, { keys: [(row) => row.values[id]] });
}

// Let the table remove the filter if the string is empty
fuzzyTextFilterFn.autoRemove = (val) => !val;

// Our table component
function Table({
  columns,
  data,
  fetchData,
  loading = false,
  pageCount: controlledPageCount,
  serverSide: manualPaginationControl = false,
}) {
  const filterTypes = React.useMemo(
    () => ({
      // Add a new fuzzyTextFilterFn filter type.
      fuzzyText: fuzzyTextFilterFn,
      // Or, override the default text filter to use
      // "startWith"
      text: (rows, id, filterValue) => {
        return rows.filter((row) => {
          const rowValue = row.values[id];
          return rowValue !== undefined
            ? String(rowValue)
                .toLowerCase()
                .startsWith(String(filterValue).toLowerCase())
            : true;
        });
      },
    }),
    []
  );

  const defaultColumn = React.useMemo(
    () => ({
      // Let's set up our default Filter UI
      Filter: DefaultColumnFilter,
    }),
    []
  );

  const {
    page, // Instead of using 'rows', we'll use page,
    // which has only the rows for the active page
    // The rest of these things are super handy, too ;)
    canPreviousPage,
    canNextPage,
    pageOptions,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
    getTableProps,
    getTableBodyProps,
    headerGroups,
    //rows,
    prepareRow,
    state,
    //visibleColumns,
    preGlobalFilteredRows,
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data,
      defaultColumn, // Be sure to pass the defaultColumn option
      filterTypes,
      initialState: { pageIndex: 0 },
      manualSortBy: manualPaginationControl, // Tell the useSortBy hook that we'll handle our own data sorting
      manualGlobalFilter: manualPaginationControl, // Tell the useGlobalFilter hook that we'll handle our own global data filtering
      manualFilters: manualPaginationControl, // Tell the useFilters hook that we'll handle our own data filtering
      manualPagination: manualPaginationControl, // Tell the usePagination
      // hook that we'll handle our own data fetching
      // This means we'll also have to provide our own
      // pageCount.
      pageCount: controlledPageCount,
    },
    useFilters, // useFilters!
    useGlobalFilter, // useGlobalFilter!
    useSortBy,
    usePagination
  );

  const [currentPages, setcurrentPage] = React.useState(1);
  const pageNeighbour = 1;

  const LEFT_PAGE = "LEFT";
  const RIGHT_PAGE = "RIGHT";

  // Listen for changes in pagination and use the state to fetch our new data
  React.useEffect(() => {
    if (typeof fetchData === "function") {
      fetchData({ page: pageIndex + 1, per_page: pageSize });
    }
  }, [fetchData, pageIndex, pageSize]);
  const pageNumbers = [];
  for (let i = 1; i <= pageOptions.length; i++) {
    if (pageNumbers.includes(i) === false) pageNumbers.push(i);
  }

  const handleMoveRight = () => {
    const currentPage = Math.max(
      0,
      Math.min(currentPages + pageNeighbour * 2 + 1, pageNumbers.length)
    );
    gotoPage(currentPage - 1);
    setcurrentPage(currentPage);
  };

  const handleMoveLeft = () => {
    const currentPage = Math.max(
      0,
      Math.min(currentPages - pageNeighbour * 2 - 1, pageNumbers.length)
    );
    gotoPage(currentPage - 1);
    setcurrentPage(currentPage);
  };

  const handleClick = (page) => (evt) => {
    const currentPage = Math.max(0, Math.min(page, pageNumbers.length));
    gotoPage(page - 1);
    setcurrentPage(currentPage);
  };

  const range = (from, to, step = 1) => {
    let i = from;
    const range = [];

    while (i <= to) {
      range.push(i);
      i += step;
    }

    return range;
  };

  const fetchPageNumbers = () => {
    const totalPages = pageNumbers.length;
    const currentPage = currentPages;
    const pageNeighbours = pageNeighbour;

    /**
     * totalNumbers: the total page numbers to show on the control
     * totalBlocks: totalNumbers + 2 to cover for the left(<) and right(>) controls
     */
    const totalNumbers = pageNeighbours * 2 + 3;
    const totalBlocks = totalNumbers + 2;

    if (totalPages > totalBlocks) {
      const startPage = Math.max(2, currentPage - pageNeighbours);
      const endPage = Math.min(totalPages - 1, currentPage + pageNeighbours);

      let pages = range(startPage, endPage);

      /**
       * hasLeftSpill: has hidden pages to the left
       * hasRightSpill: has hidden pages to the right
       * spillOffset: number of hidden pages either to the left or to the right
       */
      const hasLeftSpill = startPage > 2;
      const hasRightSpill = totalPages - endPage > 1;
      const spillOffset = totalNumbers - (pages.length + 1);

      switch (true) {
        // handle: (1) < {5 6} [7] {8 9} (10)
        case hasLeftSpill && !hasRightSpill: {
          const extraPages = range(startPage - spillOffset, startPage - 1);
          pages = [LEFT_PAGE, ...extraPages, ...pages];
          break;
        }

        // handle: (1) {2 3} [4] {5 6} > (10)
        case !hasLeftSpill && hasRightSpill: {
          const extraPages = range(endPage + 1, endPage + spillOffset);
          pages = [...pages, ...extraPages, RIGHT_PAGE];
          break;
        }

        // handle: (1) < {4 5} [6] {7 8} > (10)
        case hasLeftSpill && hasRightSpill:
        default: {
          pages = [LEFT_PAGE, ...pages, RIGHT_PAGE];
          break;
        }
      }

      return [1, ...pages, totalPages];
    }

    return range(1, totalPages);
  };

  const pages = fetchPageNumbers();

  return (
    <>
      <Row>
        <GlobalFilter
          preGlobalFilteredRows={preGlobalFilteredRows}
          globalFilter={state.globalFilter}
          setGlobalFilter={setGlobalFilter}
        />
      </Row>
      <div className="table-responsive">
        <table
          id="table-to-xls"
          className="table table-striped "
          {...getTableProps()}
        >
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                    {column.render("Header")}
                    {/* Render the columns filter UI */}
                    {/* <div>{column.canFilter ? column.render('Filter') : null}</div> */}
                    <span>
                      {column.isSorted ? (column.isSortedDesc ? "⬇" : "⬆") : ""}
                    </span>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row, i) => {
              prepareRow(row);
              return (
                <tr className="trans-tbl-row mb-1" {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    return (
                      <td className="trans-tbl-data" {...cell.getCellProps()}>
                        {cell.render("Cell")}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <div className={PaginationStyles.sente_pagination}>
        <div className={PaginationStyles.sente_pagination__container}>
          <div className={PaginationStyles.sente_pagination__pages}>
            <ul className={PaginationStyles.page_numbers}>
              <li>
                <Button
                  className={PaginationStyles.ellipsisItem}
                  disabled={!canPreviousPage}
                  onClick={() => [
                    previousPage(),
                    setcurrentPage(currentPages - 1),
                  ]}
                >
                  <span className="text-center" aria-hidden="true">
                    &laquo;
                  </span>
                </Button>
              </li>

              {pages.map((page, index) => {
                if (page === LEFT_PAGE)
                  return (
                    <li key={index} className={PaginationStyles.ellipsisItem}>
                      <a href onClick={handleMoveLeft}>
                        <span aria-hidden="true">&hellip;</span>
                      </a>
                    </li>
                  );

                if (page === RIGHT_PAGE)
                  return (
                    <li key={index} className={PaginationStyles.ellipsisItem}>
                      <a href aria-label="Next" onClick={handleMoveRight}>
                        <span aria-hidden="true">&hellip;</span>
                      </a>
                    </li>
                  );

                return (
                  <li
                    key={index}
                    className={`${PaginationStyles.paginationItem} ${
                      currentPages === page ? PaginationStyles.activeItem : ""
                    }`}
                  >
                    <a href onClick={handleClick(page)}>
                      <span className="text-white">{page}</span>
                    </a>
                  </li>
                );
              })}
              <Button
                className={`${PaginationStyles.ellipsisItem} mx-2 shadow-none`}
                disabled={!canNextPage}
                onClick={() => [nextPage(), setcurrentPage(currentPages + 1)]}
              >
                <span aria-hidden="true">&raquo;</span>
              </Button>
            </ul>
          </div>
          <div className={PaginationStyles.sente_pagination__goto}>
            <select
              className={PaginationStyles.sente_pagination__goto_trigger}
              value={pageSize}
              onChange={(e) => {
                setPageSize(Number(e.target.value));
              }}
            >
              {[10, 20, 30, 40, 50].map((pageSize) => (
                <option key={pageSize} value={pageSize}>
                  Show {pageSize}
                </option>
              ))}
            </select>
          </div>
        </div>
      </div>
      <br />
    </>
  );
}

// Define a custom filter filter function!
function filterGreaterThan(rows, id, filterValue) {
  return rows.filter((row) => {
    const rowValue = row.values[id];
    return rowValue >= filterValue;
  });
}

// This is an autoRemove method on the filter function that
// when given the new filter value and returns true, the filter
// will be automatically removed. Normally this is just an undefined
// check, but here, we want to remove the filter if it's not a number
filterGreaterThan.autoRemove = (val) => typeof val !== "number";

function TblComp(props) {
  return <Table {...props} />;
}

export default TblComp;
