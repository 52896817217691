import React from "react";
import { Route, Switch } from "react-router-dom";
import CompanyComp from "./component";
import CompanyRegForm from "./reg_form";
import { CompanyDetails } from "./company_details/component";

export default function CompanyRoutes(props) {
  return (
    <Switch>
      <Route exact path="/company/new" component={CompanyRegForm} />
      <Route path="/company/:id" component={CompanyDetails} />
      <Route component={CompanyComp} />
    </Switch>
  );
}
