import React from "react";
import refresh from "../../../../assets/img/refresh.svg";
import MaskedInput from "react-maskedinput";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { TextField } from "../../../auth/TextField";
import * as Yup from "yup";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import OTPComponent from "../../../auth/OTP/input";
import {
  ModalHeader,
  Modal,
  ModalBody,
  FormGroup,
  Row,
  Col,
  Container,
  Button,
  ModalFooter,
} from "reactstrap";
import { HandlePaymentVerification, SendVerificationCode } from "../../../../actions/payment_actions";

export const AddPayment = ({ toggle, modal }) => {
  const dispatch = useDispatch();
  const user_obj = useSelector((state) => state.authState.user_obj, shallowEqual);
  const { current_payment_obj:formData,otp_code_state, payment_state, status: responseStatus, isVerifying, is_loading: isLoading } = useSelector((state) => state.paymentState, shallowEqual);
  const [code, setCode] = React.useState("");

  const validate = Yup.object({
    currency_id: Yup.string().required("Currency id is required"),
    masked_contact: Yup.string().required("Contact is required"),
    amount: Yup.string().required("Amount is required"),
    transaction_note: Yup.string().required("Transaction note is required"),
  });

  const handleChange = (otpCode) => {
    setCode(otpCode);
  };

  const toggler = () => {
    //don't close the modal when the payment process isn't complete
    if (!isLoading && !isVerifying) {
      toggle();
    }
  }

  const requestOTP = (e) => {
    e.preventDefault();
    if(payment_state === "new_payment" || payment_state === "verification" || otp_code_state === "re-send"){
      dispatch(SendVerificationCode());
    }
  }

  /**
   * To be able to process a payment, the following conditions have to be met
   * 1. A telephone number, amount and narrative must be provided.
   * 2. An OTP sent to the registered mobile number must be entered on the verification form.
   * 3. After success/failure, the user must be able to make another payment altogether
   */
  return (
    <>
      <Modal isOpen={modal} toggle={toggler}>
        <ModalHeader toggle={toggler}>
          <span className="modal-header-text">
            New Payment
          </span>
        </ModalHeader>
        <Formik
          initialValues={formData}
          validationSchema={validate}
          onSubmit={(values) => {
            dispatch(HandlePaymentVerification(values, code, user_obj.active_company.id));
          }}
        >
          {(formik) => (
            <Form>
              <ModalBody>
                <FormGroup>
                      {(isLoading || isVerifying) &&
                        <div className="d-flex justify-content-center">
                          <img
                            src={refresh}
                            width="50px"
                            height="50px"
                            alt="Loading..."
                          />
                        </div>
                      }
                      <div className="d-flex justify-content-center">
                        <p className="upgrade-1 ms-1">
                          {payment_state === "otp_verified" && "OTP Verified"}
                        </p>
                        <p className="upgrade-1 ms-1">
                          {isLoading && "Processing Payment..."}
                          {!isLoading && responseStatus}
                        </p>
                      </div>
                  {payment_state === "new_payment" &&
                    <div className="container-fluid">
                      <Row className="mt-3">
                        <Col lg={12} md={12} sm={12} className="my-2">
                          <TextField
                            name="currency_id"
                            type="hidden"
                            placeholder="Amount"
                          />
                          <Field
                            name="masked_contact"
                            render={({ field }) => (
                              <MaskedInput
                                {...field}
                                mask="+256-111-111-111"
                                showMask
                                className="form-control"
                              />
                            )}
                          />
                          <ErrorMessage
                            component="div"
                            name="masked_contact"
                            className="error"
                          />
                        </Col>
                        <Col lg={12} md={12} sm={12} className="my-2">
                          <TextField
                            name="amount"
                            type="text"
                            placeholder="Amount"
                          />
                        </Col>
                        <Col lg={12} md={12} sm={12} className="my-2">
                          <Field
                            as="textarea"
                            name="transaction_note"
                            placeholder="Transaction note"
                            className={`custom-select form-control ${formik.errors.transaction_note
                              ? `is-invalid mb-2`
                              : ""
                              }`}
                          ></Field>
                          <ErrorMessage
                            component="div"
                            name="transaction_note"
                            className="error"
                          />
                        </Col>
                      </Row>
                    </div>
                  }
                  {payment_state === "verification" &&
                    <Row>
                      <p className="ms-2">
                        Enter the code sent to your phone/email
                      </p>
                      <div className="otp-custom mt-3">
                        <OTPComponent
                          onChange={handleChange}
                          value={code}
                        />
                      </div>
                    </Row>
                  }
                  {payment_state === "final" &&
                    <Container className="mt-1">
                      <div className="d-flex justify-content-center">
                      </div>
                    </Container>
                  }
                </FormGroup>
              </ModalBody>
              <ModalFooter>
                {payment_state === "new_payment" && 
                <Button className="pay-btn" disabled={isLoading} onClick={requestOTP} block>
                  <span className="pay-btn-text">
                    {isLoading ? "Processing..." : "Pay"}
                  </span>
                  </Button>
                }
                {payment_state === "verification" &&
                  <Button type="submit" className="pay-btn" disabled={isLoading || isVerifying} block>
                    <span className="pay-btn-text ">
                      Verify{isVerifying && "ing..."}
                    </span>
                  </Button>
                }
                {(payment_state === "verification" && otp_code_state === "re-send") &&
                <Button className="pay-btn" disabled={isLoading || isVerifying} onClick={requestOTP} block>
                  <span className="pay-btn-text">
                      Resend{isLoading && "ing..."} code
                  </span>
                  </Button>
                }
                {(payment_state === "final" && !isLoading) &&
                  <Button
                    onClick={
                      () => {
                        toggle();
                        formik.resetForm();
                      }
                    }
                    className="pay-btn"
                  >
                    <span className="text-white">Close</span>
                  </Button>
                }
              </ModalFooter>
            </Form>
          )}
        </Formik>
      </Modal>
    </>
  );
};
