import React from "react";
import { Row, Col } from "reactstrap";

const NotFound = (props) => {
  return (
    <>
      {/* ============================================================== */}
      {/* Start Page Content here */}
      {/* ============================================================== */}
      <div className="wrapper">
        <div className="container-fluid">
          <Row className="justify-content-center my-5">
            <Col lg={6} xl={4} sm={12} className="mb-4">
              <div className="error-text-box">
                <svg viewBox="0 0 600 200">
                  {/* Symbol*/}
                  <symbol id="s-text">
                    <text textAnchor="middle" x="50%" y="50%" dy=".35em">
                      404!
                    </text>
                  </symbol>
                  {/* Duplicate symbols*/}
                  <use className="text" xlinkHref="#s-text" />
                  <use className="text" xlinkHref="#s-text" />
                  <use className="text" xlinkHref="#s-text" />
                  <use className="text" xlinkHref="#s-text" />
                  <use className="text" xlinkHref="#s-text" />
                </svg>
              </div>
              <div className="text-center">
                <h3 className="mt-0 mb-2">Whoops! Page not found </h3>
                <p className="text-muted mb-3">
                  It's looking like you may have taken a wrong turn. Don't
                  worry... it happens to the best of us. You might want to check
                  your internet connection. Here's a little tip that might help
                  you get back on track.
                </p>
                <a
                  href="index-2.html"
                  className="btn btn-success waves-effect waves-light"
                >
                  Back to Previous Page
                </a>
              </div>
              {/* end row */}
            </Col>{" "}
            {/* end col */}
          </Row>
          {/* end row */}
        </div>{" "}
        {/* end container */}
      </div>
      {/* end wrapper */}
    </>
  );
};

export default NotFound;
