import React from "react";
import { Link } from "react-router-dom";
import { Row, Col, Container, Card, CardBody } from "reactstrap";

const PwdSuccess = (props) => {
  return (
    <>
      <div>
        <div className="account-pages mt-5 mb-5">
          <Container>
            <Row className="justify-content-center">
              <Col md={8} lg={6} xl={5}>
                <Card>
                  <CardBody className="p-4">
                    <div className="text-center w-75 m-auto">
                      <a href="index-2.html">
                        <span>
                          <img
                            src="assets/images/logo-dark.png"
                            alt=""
                            height={22}
                          />
                        </span>
                      </a>
                    </div>
                    <div className="text-center">
                      <div className="mt-4">
                        <div className="logout-checkmark">
                          <svg
                            version="1.1"
                            id="Layer_1"
                            xmlns="http://www.w3.org/2000/svg"
                            xmlnsXlink="http://www.w3.org/1999/xlink"
                            x="0px"
                            y="0px"
                            viewBox="0 0 161.2 161.2"
                            enableBackground="new 0 0 161.2 161.2"
                            xmlSpace="preserve"
                          >
                            <path
                              className="logout-path"
                              fill="none"
                              stroke="#d1dee4"
                              strokeMiterlimit={10}
                              d="M425.9,52.1L425.9,52.1c-2.2-2.6-6-2.6-8.3-0.1l-42.7,46.2l-14.3-16.4
                                              c-2.3-2.7-6.2-2.7-8.6-0.1c-1.9,2.1-2,5.6-0.1,7.7l17.6,20.3c0.2,0.3,0.4,0.6,0.6,0.9c1.8,2,4.4,2.5,6.6,1.4c0.7-0.3,1.4-0.8,2-1.5
                                              c0.3-0.3,0.5-0.6,0.7-0.9l46.3-50.1C427.7,57.5,427.7,54.2,425.9,52.1z"
                            />
                            <circle
                              className="logout-path"
                              fill="none"
                              stroke="#1abc9c"
                              strokeWidth={4}
                              strokeMiterlimit={10}
                              cx="80.6"
                              cy="80.6"
                              r="62.1"
                            />
                            <polyline
                              className="logout-path"
                              fill="none"
                              stroke="#1abc9c"
                              strokeWidth={6}
                              strokeLinecap="round"
                              strokeMiterlimit={10}
                              points="113,52.8
                                              74.1,108.4 48.2,86.4 "
                            />
                            <circle
                              className="logout-spin"
                              fill="none"
                              stroke="#d1dee4"
                              strokeWidth={4}
                              strokeMiterlimit={10}
                              strokeDasharray="12.2175,12.2175"
                              cx="80.6"
                              cy="80.6"
                              r="73.9"
                            />
                          </svg>
                        </div>
                      </div>
                      <h3>Kudos!</h3>
                      <p className="text-muted">
                        {" "}
                        You have successfully created your password.
                      </p>
                    </div>
                  </CardBody>{" "}
                  {/* end card-body */}
                </Card>
                {/* end card */}
                <Row className=" mt-3">
                  <Col lg={12} sm={12} md={12} className="text-center">
                    <p className="text-primary">
                      Back to{" "}
                      <Link className="text-primary" to="/login">
                        Login
                      </Link>
                    </p>
                  </Col>{" "}
                  {/* end col */}
                </Row>
                {/* end row */}
              </Col>{" "}
              {/* end col */}
            </Row>
            {/* end row */}
          </Container>
          {/* end container */}
        </div>
        {/* end page */}
      </div>
    </>
  );
};

export default PwdSuccess;
