import React, { useState } from "react";
import { KEY } from "../../../constants";

const OTPInput = (props) => {
  const [doms] = useState([]);

  const {
    otpLength,
    numberOnly,
    type,
    pattern,
    disabled,
    error,
    errorMessage,
    onChange,
    autoFocus,
    value,
    errorMessageClass,
    ...rest
  } = props;

  const inputFilter = pattern || (numberOnly ? /[^0-9]/gi : /[^0-9]/gi);

  const focusOn = (i) => {
    if (doms[i]) doms[i].focus();
  };

  const handleChange = (e, i) => {
    e.target.value = e.target.value.replace(inputFilter, "");
    if (e.target.value.length > 1) e.target.value = e.target.value.slice(0, 1);
    if (e.target.value !== "") focusOn(i + 1);
    const otpCode = doms.map((d) => d.value).join("");
    onChange(otpCode);
  };

  const handleKeyDown = (e, i) => {
    if (e.keyCode === KEY.backspace && e.target.value === "") focusOn(i - 1);
    if (e.keyCode === KEY.left) focusOn(i - 1);
    if (e.keyCode === KEY.right) focusOn(i + 1);
  };

  const handlePaste = (e, i) => {
    const pastedString = e.clipboardData
      .getData("text/plain")
      .replace(inputFilter, "")
      .slice(0, otpLength - i);

    const pastedData = pastedString.split("");

    doms[otpLength - 1].focus();
    for (let pos = i; pos < otpLength; pos += 1) {
      doms[pos].value = pastedData.shift();
    }
    const otpCode = doms.map((d) => d.value).join("");
    onChange(otpCode);
  };

  const codeBoxItems = [...Array(otpLength).keys()].map((i) => (
    <div className={`otpInputWrapper`} key={i}>
      <input
        type={type}
        autoComplete="false"
        autoCorrect="off"
        autoCapitalize="off"
        spellCheck="false"
        className={`otpInput`}
        ref={(dom) => {
          doms[i] = dom;
        }}
        onFocus={(e) => e.target.select()}
        onChange={(e) => handleChange(e, i)}
        onKeyDown={(e) => handleKeyDown(e, i)}
        onPaste={(e) => handlePaste(e, i)}
        disabled={disabled}
        defaultValue={value && value[i]}
        {...rest}
      />
    </div>
  ));

  return (
    <div>
      <div className={`otpWrapper`}>{codeBoxItems}</div>
      {error && errorMessage && (
        <p className={`errorMessage`}>{errorMessage}</p>
      )}
    </div>
  );
};

OTPInput.defaultProps = {
  value: "",
  otpLength: 6,
  type: "text",
  numberOnly: false,
  autoFocus: false,
  pattern: null,
  disabled: false,
  error: false,
  errorMessage: null,
  onChange: () => {},
};

export default OTPInput;
