const CollectionFormState = {
  id: "",
  amount: "",
  msisdn: "",
  spc_id: "",
  transaction_note: "",
  currency_id: "",
  masked_contact: "",
};

const CollectionInitState = {
  collection_list: [],
  grouped_collection_list: [],
  step: 1,
  current_collection_obj: CollectionFormState,
  total: 0,
  pageCount: 1,
  totalAggregate: 0,
  recentTransactions: [],
  modalClose: false,
};

export const CollectionReducer = (prevState = CollectionInitState, action) => {
  const form_state = prevState.current_collection_obj;
  let new_formState;
  switch (action.type) {
    case "COLLECTION_ERROR":
      return { ...prevState, modalClose: true };
    case "FETCH_AGGREGATE_COLLECTION_SUCCESS":
      return {
        ...prevState,
        totalAggregate: action.payload.total,
        grouped_collection_list: action.payload.data,
      };
    case "FETCH_RECENT_COLLECTION_SUCCESS":
      return {
        ...prevState,
        recentTransactions: action.payload.data,
      };

    case "COLLECTION_INPUT_CHANGE":
      new_formState = { ...form_state, ...action.changedField };
      return { ...prevState, current_collection_obj: new_formState };
    case "CLEAR_COLLECTION_FORM":
      return { ...prevState, current_collection_obj: CollectionFormState };
    case "INIT_COLLECTION":
      return { ...prevState, step: action.step };
    case "EDIT_COLLECTION":
      new_formState = { ...action.current_collection_obj };
      return { ...prevState, current_collection_obj: new_formState };
    case "DELETE_COLLECTION_SUCCESS":
      let new_list = prevState.collection_list.filter(
        (current_rec) => current_rec.id !== action.record_id
      );
      return { ...prevState, collection_list: new_list };
    case "ADD_COLLECTION_SUCCESS":
      return { modalClose: true };
    case "ADD_TRANSACTION_FAILED":
      return prevState;
    case "CHECK_TRANSACTION_SUCCESS":
      //return FillDataList(prevState,action.request)
      return prevState;
    case "CHECK_TRANSACTION_FAILED":
      //return FillDataList(prevState,action.request)
      return prevState;
    default:
      return prevState;
  }
};