import React, { useRef, useEffect } from "react";
import { connect } from "react-redux";
import { findInArray } from "../../../../constants/GenHelpers";
import {
  /*HandleCompanyDocumentDelete, */ HandleCompanyDocumentFetch,
  HandleCompanyDocumentSubmit,
} from "../../../../actions/company_document_actions";
import { HandleDocumentTypeFetch } from "../../../../actions/types/document_type_actions";
import { Icons } from "../../icons";
import { Row, Col, Button, Input, Label } from "reactstrap";

const DocumentControls = ({ uploadedDoc, docType, company_id, dispatch }) => {
  const fileInput = useRef(null);
  //const [customTxt,setCustomText] = React.useState("No file chosen, yet.")

  const handleFileUpload = (document_type_id /*,doc_id=null*/) => (e) => {
    e.preventDefault();
    const { /*value:cur_value,name,*/ files } = e.target;

    /*if (cur_value) {
      const matching = cur_value.toString().match(/[/\\]([\w\d\s.\-()]+)$/)
      setCustomText(matching[1]);
    }*/
    dispatch(
      HandleCompanyDocumentSubmit({
        id: typeof uploadedDoc !== "undefined" ? uploadedDoc.id : null,
        document_type_id,
        company_id,
        doc_file: files[0],
      })
    );
  };

  const handleUploadBtnClick = (e) => {
    e.preventDefault();
    fileInput.current.click();
  };
  const handleDeleteBtnClick = (/*doc_id*/) => (e) => {
    e.preventDefault();
    //dispatch(HandleCompanyDocumentDelete(doc_id));
  };

  return (
    <>
      <Input
        ref={fileInput}
        type="file"
        name="doc_file"
        hidden
        onChange={handleFileUpload(docType.id)}
      />
      <div
        className="btn-group"
        role="group"
        aria-labelledby={`${docType.document_name} label`}
      >
        {(typeof uploadedDoc === "undefined" ||
          (typeof uploadedDoc !== "undefined" &&
            uploadedDoc.approved === 0)) && (
          <Button
            type="button"
            size="sm"
            className="btn-info"
            title={
              typeof uploadedDoc !== "undefined"
                ? "Replace file"
                : "Upload file"
            }
            onClick={handleUploadBtnClick}
          >
            <img
              src="/images/upload.svg"
              height="15px"
              width="15px"
              alt="upload"
              className="ml-1"
            />
          </Button>
        )}
        {typeof uploadedDoc !== "undefined" && uploadedDoc.approved === 0 && (
          <Button color="danger" size="sm" type="button"
            onClick={handleDeleteBtnClick(uploadedDoc.id)}
          >
            <img
              src="/images/deleted.svg"
              height="15px"
              width="15px"
              alt="delete"
              className="ml-1"
            />
          </Button>
        )}
      </div>
    </>
  );
};
const DocIcon = ({ uploadedDoc }) => {
  if (typeof uploadedDoc === "undefined") {
    return (
      <img
        src={Icons.faq_active_icon}
        height="30px"
        width="30px"
        alt="File"
        className="ml-1"
      />
    );
  }
  /*
  We ought to first resolve the issues of forbidden access from the server before we uncomment this
  const fileExt = uploadedDoc.dope_name.match(/\.(jpeg|png|gif)/);
  if(fileExt.length){
    return <img src={uploadedDoc.dope_name} height="30px" width="30px" alt="File" className="ml-1"/>
  }*/
  return (
    <img
      src={Icons.pdf}
      height="30px"
      width="30px"
      alt="File"
      className="ml-1"
    />
  );
};

export function CompanyDocs({company,docsList,docTypesList,dispatch}) {
  useEffect(() => {
    dispatch(HandleDocumentTypeFetch());
  }, [dispatch]);
  useEffect(() => {
    company.id &&
      dispatch(HandleCompanyDocumentFetch(company.id));
  }, [dispatch,company]);
  return (
    <div>
      <span className="lead">Documents</span>
      {typeof docTypesList !== "undefined" &&
        company.id &&
        docTypesList.map((docType, key) => {
          const uploadedDoc = findInArray(
            docsList,
            docType.id,
            "document_type_id"
          );
          return (
            <Row key={key}>
              <Col sm={1}>
                <DocIcon uploadedDoc={uploadedDoc} />
              </Col>
              <Col sm={8}>
                {
                  <Label className="font-weight-bold">
                    {docType.document_name}{" "}
                    {docType.required_doc === 1 &&
                      typeof uploadedDoc === "undefined" &&
                      company.status !== "approved" && (
                        <span className="text-danger">*</span>
                      )}
                    {typeof uploadedDoc !== "undefined" &&
                      uploadedDoc.approved === 1 && (
                        <img
                          src={"./images/check-icon.svg"}
                          height="10px"
                          width="10px"
                          alt="Approved document"
                          className="ml-1"
                          title={`${uploadedDoc.approval_text}`}
                        />
                      )}
                  </Label>
                }
              </Col>
              <Col sm={3}>
                {["new", "pending_review"].includes(company.status) && (
                  <DocumentControls
                    company_id={company.id}
                    uploadedDoc={uploadedDoc}
                    docType={docType}
                    dispatch={dispatch}
                  />
                )}
              </Col>
            </Row>
          );
        })}
    </div>
  );
}
// connect the store for the company dispatches and state
const mapStateToProps = (state) => {
  return {
    company: state.companyState.current_company_obj,
    document_type_list: state.documenttypereducer.document_type_list,
    docsList: state.companyDocumentState.company_document_list,
    docTypesList: state.documenttypereducer.document_type_list,
    isProgress: state.companyDocumentState.isProgress,
  };
};

const CompanyDocRedux = connect(mapStateToProps)(CompanyDocs);
export default CompanyDocRedux;
