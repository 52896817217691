import { allconstants, base_url } from "../constants/constants";
import { decData } from "../constants";
import Axios from "axios";
import { HandleCompanyFetch } from "./companyActions";

export const HandlePaymentInput = (e) => {
  const { value, name } = e.target ? e.target : e;
  return { type: "PAYMENT_INPUT_CHANGE", changedField: { [name]: value } };
};
export const clearPaymentForm = () => {
  return { type: "CLEAR_PAYMENT_FORM" };
};

export const PaymentFetchSuccess = (payload, isPayment = false) => {
  return { type: "FETCH_PAYMENT_SUCCESS", payload, isPayment };
};
export const TransactionFetchSuccess = (payload, isTransaction = false) => {
  return { type: "FETCH_TRANSACTION_SUCCESS", payload, isTransaction };
};
export const GroupedPaymentFetchSuccess = (payload, isPayment = false) => {
  return { type: "FETCH_GROUPED_PAYMENT_SUCCESS", payload, isPayment };
};
export const PaymentAddSuccess = (payload) => {
  return { type: "ADD_PAYMENT_SUCCESS", new_record: payload.transaction };
};

export const setPaymentResponse = (msg) => {
  return { type: "PAYMENT_RESPONSE", msg };
};

const setVerificationState = (payload = true) => {
  return { type: "SET_VERIFICATION_STATE", payload };
};

const setOTPCodeState = (payload = true) => {
  return {type:"SET_OTP_CODE_STATE", payload};
}

export const PaymentDeleteSuccess = (msg, record_id) => {
  return { type: "DELETE_PAYMENT_SUCCESS", record_id };
};

export const setPaymentState = (payload = 'new_payment') => {
  return { type: "SET_PAYMENT_STATE",payload };
};

const setIsLoading = (payload) => {
  return { type: "IS_LOADING", payload };
};

export const PaymentReqError = (msg) => {
  return { type: "PAYMENT_ERROR" };
};
export const editPayment = (obj) => {
  return { type: "EDIT_PAYMENT", current_payment_obj: obj };
};
export const HandlePaymentVerification =
  (form_data, code, url_id) => (dispatch) => {
    dispatch(setPaymentResponse(""));
    dispatch(setVerificationState(true));
    const url = allconstants.apiurl + `verify`;
    const data = {
      code: code,
    };
    allconstants.axios
      .post(url, data, { headers: allconstants.headers })
      .then((response) => {
        if (response.status === 200) {
          dispatch(setPaymentState("otp_verified"));
          dispatch(HandlePaymentSubmit(form_data, url_id));
        }
      })
      .catch((err) => {
        if (err.response.status === 422) {
          dispatch(setPaymentResponse(err.response.data.message));
        }
        dispatch(setOTPCodeState("re-send"));
      })
      .finally(()=>{
        dispatch(setVerificationState(false));
      });
  };

export const VerifyCode = (form_data) => (dispatch) => {
  if (form_data.amount.match(/^[0-9]+$/) == null) {
  } else {
    dispatch(SendVerificationCode());
  }
};

export const SendVerificationCode = () => (dispatch) => {
  dispatch(setPaymentResponse(""));
  dispatch(setIsLoading(true));
  const access_token = decData(sessionStorage.getItem("token"));
  Axios.get(base_url("sendVerification"), {
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${access_token}`,
    },
  })
    .then((res) => {
      if (res.status === 200 || res.status === 201) {
        dispatch(setPaymentState("verification"));
      }
    })
    .catch((err) => {
      console.error(err);
    })
    .finally(()=>{
      dispatch(setIsLoading(false));
    });
};

export const HandlePaymentSubmit = (form_data, url_id) => (dispatch) => {
  dispatch(setIsLoading(true));
  var name = form_data.masked_contact.replace(/\D+/g, "");
  const url = allconstants.apiurl + "company/" + url_id + "/transcn/paymt";
  const req_type = form_data.id
    ? allconstants.axios.put
    : allconstants.axios.post;
  req_type(
    url,
    { ...form_data, msisdn: "+" + name },
    { headers: allconstants.headers }
  )
    .then((response) => {
      if (response.status === 202 || response.status === 200) {
        dispatch(PaymentAddSuccess(response.data));
        dispatch(setPaymentResponse(response.data.message));
        dispatch(clearPaymentForm());
        //lets get the latest company data with the balance
        dispatch(HandleCompanyFetch(url_id, true))
      }
      dispatch(setPaymentState("final"));
    })
    .catch((error) => {
      dispatch(setPaymentState("new_payment"));
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        dispatch(setPaymentResponse(error.response.data.message));
        console.error(error.response.data);
        console.error(error.response.status, error.response.statusText);
      } else if (error.request) {
        // The request was made but no response was received
        console.error(error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log("Error", error.message);
      }
      console.log(error.config);
    })
    .finally(()=>{
      dispatch(setIsLoading(false));
    });
};
export const HandlePaymentDelete = (record_id) => (dispatch) => {
  const url = allconstants.apiurl + "payment/" + record_id;

  allconstants.axios
    .delete(url, { headers: allconstants.headers })
    .then((response) => {
      if (response.status === 201 || response.status === 200) {
        dispatch(PaymentDeleteSuccess(response.data.message, record_id));
      }
    })
    .catch((error) => {
      if (error.response) {
        console.log(error.response);
      } else if (error.request) {
        console.log(error.request);
      } else {
        // toastr.error("Please check your network and try again");
      }
    });
};

export function GetPaymentStatus(ref_no) {
  return function (dispatch) {
    allconstants.axios
      .get(allconstants.apiurl + "transcn/paymt/" + ref_no + "/status", {
        headers: allconstants.headers,
      })
      .then((response) => {
        if (response.status === 200) {
          dispatch(PaymentFetchSuccess(response.data.data));
        }
      })
      .catch((failure_reason) => {
        console.error(failure_reason);
      });
  };
}

export const HandlePaymentFetch =
  (company_id, params = {}) =>
  (dispatch) => {
    dispatch(setIsLoading(true));
    allconstants.axios
      .get(allconstants.apiurl + "company/" + company_id + "/transcn/paymt", {
        headers: allconstants.headers,
        params,
      })
      .then((response) => {
        if (response.status === 200) {
          dispatch(PaymentFetchSuccess(response.data.data));
        }
      })
      .catch((failure_reason) => {
        console.error(failure_reason);
      })
      .finally(()=>{
        dispatch(setIsLoading(false));
      });
  };

export const HandleTransactionsFetch =
  (company_id, params = {}) =>
  (dispatch) => {
    dispatch(setIsLoading(true));
    allconstants.axios
      .get(allconstants.apiurl + "company/" + company_id + "/transcn", {
        headers: allconstants.headers,
        params,
      })
      .then((response) => {
        if (response.status === 200) {
          dispatch(TransactionFetchSuccess(response.data.data));
        }
      })
      .catch((failure_reason) => {
        console.error(failure_reason);
      })
      .finally(()=>{
        dispatch(setIsLoading(false));
      });
  };

export const HandleGroupedPaymentFetch =
  (company_id, params = {}) =>
  (dispatch) => {
    allconstants.axios
      .get(
        allconstants.apiurl + "company/" + company_id + "/aggtransac/paymt",
        {
          headers: allconstants.headers,
          params,
        }
      )
      .then((response) => {
        if (response.status === 200) {
          dispatch(GroupedPaymentFetchSuccess(response.data));
        }
      })
      .catch((failure_reason) => {
        console.error(failure_reason);
      });
    return null;
  };

export const handleApprovalStatusChange = (form_data) => (dispatch) => {
  const url =
    allconstants.apiurl + `payment/${form_data.id}/action/${form_data.status}`;
  const data = {
    reason_for_decision: form_data.reason_for_decision,
  };
  allconstants.axios
    .post(url, data, { headers: allconstants.headers })
    .then((response) => {
      if (response.status === 200) {
        dispatch(HandlePaymentFetch(form_data.id, true));
        dispatch(PaymentAddSuccess(response.data, true));
      }
    })
    .catch((error) => {
      if (error.response) {
        console.log(error.response);
      } else if (error.request) {
        console.log(error.request);
      }
    });
};
