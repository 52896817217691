import { base_url } from "../constants/constants";
import Axios from "axios";
import { AxiosHeaders, decData } from "../constants";
import { HandleCompanyFetch } from "./companyActions";
import { allconstants } from "../constants/constants";

const doAuthSuccess = (payload) => {
  return { type: "DO_AUTH_SUCCESS", payload };
};

const setLoginStatus = (payload) => {
  return { type: "SET_LOGIN_STATUS", payload };
};

const setIsVerifyingOTP = (payload = true) => {
  return { type: "SET_IS_VERIFYING", payload };
};

const setCanSendOTPRequest = (payload = true) => {
  return { type: "SET_CAN_SEND_OTP", payload };
};

export const isProcessing = (payload = true) => {
  return {
    type: "IS_PROCESSING",
    payload,
  };
};

export const setLoginPage = (pageName) => {
  return { type: "SET_LOGIN_PAGE_NAME",payload:pageName}
}
const setCDTimer = (seconds = 60) => {
  return { type:"SET_COUNTDOWN_TIMER",payload:seconds};
}
const reduceCDTime = (byseconds = 1) => {
  return { type:"REDUCE_COUNTDOWN_TIME",payload:byseconds};
}
let interval;
export const resetTimer = (dispatch) => {
  dispatch(setCDTimer(0));
  clearInterval(interval);
}

const startInterval = (dispatch)=>{
  dispatch(setCDTimer())
  interval = setInterval(() => dispatch(reduceCDTime()), 1000);
}

const linkExpired = () => {
  return { type: "LINK_EXPIRED" };
};

const emailSent = () => {
  return { type: "EMAIL_SENT" };
};

const fetchUserSession = (payload) => {
  return { type: "ADD_USER_SESSION", payload };
};
export const checkAuthentication = () => {
  return { type: "UPDATE_USER_SESSION" };
};
const doLogoutSuccess = (message) => {
  return { type: "SIGNOUT_USER" };
};
const doAuthError = (payload) => {
  return { type: "DO_AUTH_ERROR", payload };
};

export const verifyMerchant = () => {
  return { type: "EMAIL_VERIFIED" };
};

export const verifyCodeFailed = (payload) => {
  return { type: "CODE_VERIFY_FAILED", payload };
};

export const verifyMerchantPhone = () => {
  return { type: "PHONE_VERIFIED" };
};

export const doAuthAction = (form_data) => (dispatch) => {
  dispatch(isProcessing(true));
  const url = base_url("merchant/login");
  const data = { ...form_data };
  Axios.post(url, data, { headers: AxiosHeaders })
    .then((response) => {
      if (response.status === 201 || response.status === 200) {
        // dispatch(sendOTP(response));
        dispatch(setLoginStatus({...response.data,message:null}));
        dispatch(isProcessing(false));
        dispatch(setLoginPage("OTP"));
        startInterval(dispatch);
        setTimeout(function(){
          dispatch(setCanSendOTPRequest(true));
        },1*60*1000);//allow resending after one minute
        // dispatch(HandleUserFetch(response));
      } else {
        dispatch(isProcessing(false));
      }
    })
    .catch((error) => {
      dispatch(isProcessing(false));
      if (error.response) {
        dispatch(doAuthError(error.response.data.message));
      } else if (error.request) {
        console.log(error.request);
      } else {
        console.log("Bad/No internet connection");
      }
    });
};

export const HandleUserFetch =
  (auth_details = {}) =>
  (dispatch) => {
    dispatch(isProcessing());
    Axios.get(base_url("profile"), {
      headers:{
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "*",
              Authorization: `Bearer ${auth_details.access_token}`,
            },
    })
      .then((response) => {
        if (response.status === 200) {
          if (response.data) {
            dispatch(fetchUserSession(response.data)); //set the token data
            dispatch(doAuthSuccess(auth_details)); //set the user object
            dispatch(checkAuthentication())
            dispatch(HandleCompanyFetch(response.data.id));
            //dispatch(updateExpiryTime(obj.data.access_token));
          }
        }
        dispatch(isProcessing(false));
      })
      .catch((error) => {
        dispatch(isProcessing(false));
      });
    return null;
  };

export const ResendEmail = () => (dispatch) => {
  const access_token = decData(sessionStorage.getItem("token"));
  dispatch(isProcessing());
  Axios.get(base_url("email/resend"), {
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${access_token}`,
    },
  })
    .then((res) => {
      if (res.status === 200 || res.status === 201) {
        dispatch(emailSent());
      }
      dispatch(isProcessing(false));
    })
    .catch((err) => {
      dispatch(isProcessing(false));
    });
};

export const sendOTP = (access_token) => (dispatch) => {
  dispatch(isProcessing(true));
  dispatch(setCanSendOTPRequest(false));
  Axios.get(base_url(`sendSMS`), {
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${access_token}`,
    },
  })
    .then((res) => {
      dispatch(isProcessing(false));
      resetTimer(dispatch);
      startInterval(dispatch);//start OTP resend timer
      setTimeout(function(){
        dispatch(setCanSendOTPRequest());
      },1*60*1000);//allow resending after one minute
    })
    .catch((error) => {
      dispatch(isProcessing(false));
      dispatch(setCanSendOTPRequest(true));
      if (error.response) {
        dispatch(verifyCodeFailed(error.response.status,error.response.statusText));
      } else if (error.request) {
        console.log(error.request);
      } else {
        console.log("Bad/No internet connection");
      }
    });
};
export const verify = (torken_id, torken) => (dispatch) => {
  const access_token = decData(sessionStorage.getItem("token"));
  dispatch(isProcessing());
  Axios.get(base_url(`email/verify/${torken_id}/${torken}`), {
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${access_token}`,
    },
  })
    .then((res) => {
      if (res.status === 200 || res.status === 201 || res.status === 204) {
        dispatch(verifyMerchant());
      } else {
        dispatch(linkExpired());
      }
      dispatch(isProcessing(false));
    })
    .catch((err) => {
      dispatch(isProcessing(false));
    });
};

export const verifyOTP = (token, auth_creds) => (dispatch) => {
  dispatch(setIsVerifyingOTP(true));
  dispatch(setCanSendOTPRequest(false));
  const url = allconstants.apiurl + "verify";
  const data = {
    code: token,
  };
  allconstants.axios
    .post(url, data, {
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        Authorization: `Bearer ${auth_creds.access_token}`,
      },
    })

    .then((res) => {
      if (res.status === 200 || res.status === 201 || res.status === 204) {
        dispatch(HandleUserFetch(auth_creds));
        resetTimer(dispatch);
      } else {
        dispatch(linkExpired());
      }
      dispatch(setIsVerifyingOTP(false));
    })
    .catch((error) => {
      dispatch(setIsVerifyingOTP(false));
      dispatch(setCanSendOTPRequest(true));
      if (error.response) {
        dispatch(verifyCodeFailed(error.response.status,error.response.statusText));
      } else if (error.request) {
        console.log(error.request);
      } else {
        console.log("Bad/No internet connection");
      }
    });
};

export const RefreshAuthToken = () => (dispatch) => {
  const access_token = decData(sessionStorage.getItem("refresh_token"));
  const url = allconstants.apiurl + "merchant/refresh-token";
  const data = {
    refresh_token: access_token,
  };

  allconstants.axios
    .post(url, data, {
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
    })
    .then((res) => {
      dispatch(doAuthSuccess(res.data));
      dispatch(checkAuthentication());
    })
    .catch((error) => {
      if (error.response) {
        console.log(error.response);
      } else if (error.request) {
        console.log(error.request);
      } else {
        // toastr.error("Please check your network and try again");
      }
    });
};

export const HandleLogout = () => (dispatch) => {
  const url = base_url("logout");
  const torken = decData(sessionStorage.getItem("token"));
  Axios.delete(url, {
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${torken}`,
    },
  })
    .then((res) => {
      if (res.status === 201 || res.status === 200 || res.status === 204) {
        dispatch(doLogoutSuccess("success"));
      }
    })
    .catch((error) => {
      if (error.response) {
        dispatch(doLogoutSuccess("success"));
        console.log(error.response);
      } else if (error.request) {
        console.log(error.request);
      } else {
        // toastr.error("Please check your network and try again");
      }
    });
};
