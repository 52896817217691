import React, {useState} from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { Button } from "reactstrap";
import { verifyOTP, sendOTP, setLoginPage } from "../../../actions/authAction";
import OTPInput from "./input";


export default function OTPComponent() {
    const dispatch = useDispatch();
    const selectorFn = (state) => state.authState;
    const authState = useSelector(selectorFn, shallowEqual);
    const { login_status, is_loading, can_send_otp, is_verifying,countdown } = authState;
    const [otp, setOtp] = useState("");
    return <>
        <span className="pass-reset-text">
            Enter the code sent to your phone/email
        </span>
        <div className="otp-custom mt-3">
            <OTPInput onChange={setOtp} value={otp} />
        </div>

        <div className="mt-2">
            <Button
                size="sm"
                className="sign-in-btn my-1 text-white"
                block
                disabled={otp.length < 6 || is_verifying }
                onClick={(e) =>{ e.preventDefault(); dispatch( verifyOTP(otp, login_status)); }
                }
            >
            {is_verifying &&<div
              className="spinner-grow spinner-grow-sm"
              role="status"
            ></div>}
                Verify{is_verifying &&"ing..."} OTP
            </Button>
        </div>
        <div className="text-right mt-2">
            <p className="">
                <span className="pass-reset-text">
                    Didn't get the OTP?&nbsp;
                </span>
                <span className="">
                    <Button
                        size="sm"
                        className="sign-in-btn my-1 ml-2 shadow-none text-white"
                        disabled={!can_send_otp || is_loading}
                        onClick={(e) => {
                            e.preventDefault();
                            dispatch(sendOTP(login_status.access_token));
                          }
                        }
                    >
                    {is_loading && <div
                      className="spinner-grow spinner-grow-sm"
                      role="status"
                    ></div>}
                        Resend{is_loading && "ing..."}&nbsp;{!is_loading && countdown > 0 && `(${countdown}s)`}
                    </Button>
                </span>
            </p>
        </div>
        <div className="text-center">
            <div className="text-center">
                <a
                    href="/"
                    className="sign-up-text"
                    onClick={(e) =>{ e.preventDefault(); dispatch(setLoginPage("default")); }}
                >
                    Back to Login
                </a>
            </div>
        </div>
    </>
}