import React from "react";
import { connect } from "react-redux";
import { HandleCompanySubmit } from "../../../actions/companyActions";
import { useSelector } from "react-redux";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { TextField } from "../../auth/TextField";
import * as Yup from "yup";
import { Card, CardBody, Row, Col, CardFooter, Button } from "reactstrap";

function CompanyDetails(props) {
  const { isCompProgress, formState, dispatch } = props;
  const countries = useSelector((state) => state.CountryState.country_list);

  const validate = Yup.object({
    company_name: Yup.string()
      .max(20, "Must be 20 characters or less")
      .required("Company name is required"),
    reg_no: Yup.string()
      .max(20, "Must be 20 characters or less")
      .required("Registration number is required"),
    no_of_employees: Yup.string().required("No of employees required"),
    company_type: Yup.string().required("Company type is required"),
    country_id: Yup.string().required("Country is required"),
    address: Yup.string().required("Address is required"),
    description: Yup.string().required("description is required"),
  });

  return (
    <Card>
      <Formik
        enableReinitialize={true}
        initialValues={{
          company_name: formState.company_name,
          reg_no: formState.reg_no,
          no_of_employees: formState.no_of_employees,
          company_type: formState.company_type,
          country_id: formState.country_id,
          address: formState.address,
          description: formState.description,
        }}
        validationSchema={validate}
        onSubmit={(values) => {
          dispatch(HandleCompanySubmit(values, props.merchant_id));
        }}
      >
        {(formik) => (
          <div>
            <Form>
              <CardBody>
                <p>
                  <span className="lead font-weight-bold">
                    Business Details
                  </span>{" "}
                  <br />
                  <span style={{ backgroundColor: "#8BE78B" }}>
                    {formState.status_text}
                  </span>
                </p>
                <Row>
                  <Col lg={6} md={6} sm={6}>
                    <TextField
                      name="company_name"
                      type="text"
                      value={formik.values.company_name}
                      placeholder="Company Name"
                    />
                  </Col>
                  <Col lg={6} md={6} sm={6}>
                    <TextField
                      name="reg_no"
                      type="text"
                      value={formik.values.reg_no}
                      placeholder="Reg No"
                    />
                  </Col>
                </Row>
                <Row>
                  <Col lg={6} md={6} sm={6}>
                    <Field
                      as="select"
                      name="company_type"
                      className={`custom-select form-control  ${
                        formik.errors.company_type ? `is-invalid` : ""
                      }`}
                    >
                      <option defaultValue>
                        {formik.values.company_type != null
                          ? formik.values.company_type
                          : `choose company type`}
                      </option>
                      <option value="Technology Firm">Tech Firm</option>
                      <option value="Construction">Construction</option>
                      <option value="Consultancy">Consultancy</option>
                      <option value="Real Estate">Real Estate</option>
                      <option value="NGO">NGO</option>
                      <option value="Other">Other</option>
                    </Field>
                    <ErrorMessage
                      component="div"
                      name="company_type"
                      className="error"
                    />
                  </Col>
                  <Col lg={6} sm={6} md={6}>
                    <TextField
                      name="no_of_employees"
                      type="text"
                      placeholder="No. of employees"
                      value={formik.values.no_of_employees}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col lg={12} md={12} sm={12} className="my-2">
                    <Field
                      as="select"
                      name="country_id"
                      className={`custom-select form-control ${
                        formik.errors.country_id ? `is-invalid mb-2` : ""
                      }`}
                    >
                      <option defaultValue>Choose Country</option>
                      {countries.map((country) => (
                        <option value={country.id} key={country.id}>
                          {country.country_name}
                        </option>
                      ))}
                    </Field>
                    <ErrorMessage
                      component="div"
                      name="country_id"
                      className="error"
                    />
                  </Col>
                </Row>
                <Row>
                  <Col lg={12} md={12} sm={12} className="my-2">
                    <Field
                      as="textarea"
                      name="description"
                      value={formik.values.description}
                      placeholder="Describe your business"
                      className={`custom-select form-control ${
                        formik.errors.description ? `is-invalid mb-2` : ""
                      }`}
                    ></Field>
                    <ErrorMessage
                      component="div"
                      name="description"
                      className="error"
                    />
                  </Col>
                </Row>
                <Row>
                  <Col lg={12} md={12} sm={12} className="my-2">
                    <Field
                      as="textarea"
                      name="address"
                      value={formik.values.address}
                      placeholder="Location"
                      className={`custom-select form-control ${
                        formik.errors.address ? `is-invalid mb-2` : ""
                      }`}
                    ></Field>
                    <ErrorMessage
                      component="div"
                      name="address"
                      className="error"
                    />
                  </Col>
                </Row>
              </CardBody>
              {/* {["new", "pending_review"].includes(props.formState.status) && ( */}
              <CardFooter>
                <Button
                  // disabled={isCompProgress}
                  type="submit"
                  size="sm"
                  className="pay-btn"
                  block
                >
                  <span className="pay-btn-text">
                    {!isCompProgress ? "Save" : "Processing..."}
                  </span>
                </Button>
              </CardFooter>
              {/* )} */}
            </Form>
          </div>
        )}
      </Formik>
    </Card>
  );
}
// connect the store for the company dispatches and state
const mapStateToProps = (state) => {
  return {
    formState: state.companyState.current_company_obj,
    merchant_id: state.authState.user_obj.id,
    isCompProgress: state.companyState.isCompProgress,
  };
};

const CompanyReduxComp = connect(mapStateToProps)(CompanyDetails);
export default CompanyReduxComp;
