import React from "react";
import { Modal, ModalHeader, ModalBody } from "reactstrap";

const ViewDocument = ({ modal, toggle, ticket }) => {

  return (
    <>
      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>
          <span className="modal-header-text">Document</span>
        </ModalHeader>
        <ModalBody>
          <div className="container-fluid">
            <iframe
              title="Uploaded document"
              src={`${ticket.file_path}`}
              width="100%"
              height="500rem"
              frameborder="0"
            ></iframe>
          </div>
        </ModalBody>
      </Modal>
    </>
  );
};

export default ViewDocument;
