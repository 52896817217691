import React, { useCallback, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { HandleSinglePayFetch } from "../../../actions/PayReqs";
import { useLocation } from "react-router-dom";
import { SingleReqTable } from "./singleReqTable";
import { Card, Col } from "reactstrap";

export const SingleLinks = ({dispatch,single_req_list,total,pageCount}) => {
  const location = useLocation();
  const { request_id } = location.state;
  const fetchIdRef = useRef(0);
  useEffect(() => {
    dispatch(HandleSinglePayFetch(request_id));
  }, [dispatch,request_id]);

  const fetchData = useCallback((pageOptions) => {
    const fetchId = ++fetchIdRef.current;
    if (fetchId === fetchIdRef.current) {
      dispatch(HandleSinglePayFetch(request_id, {
        ...pageOptions,
      }));
    }
  }, [dispatch,request_id]);

  const tableProps = {
    data: single_req_list,
    total: total,
    pageCount: pageCount,
    fetchData,
    serverSide: true,
  };
  return (
    <Col md={10} sm={12} lg={12}>
      <Card className="p-3">
        <Col md={12} lg={12} className="m-1">
          {typeof single_req_list !== "undefined" ? (
            <SingleReqTable {...tableProps} />
          ) : (
            <span className="lead m-5">Loading table...</span>
          )}
        </Col>
      </Card>
    </Col>
  );
};

// connect the store for the PayReq dispatches and state
const mapSingleReqStateToProps = (state) => {
  return {
    single_req_list: state.PayReqState.single_pay_req,
    total: state.PayReqState.total,
    pageCount: state.PayReqState.pageCount,
  };
};
export const SingleReqReduxComp = connect(
  mapSingleReqStateToProps
)(SingleLinks);
