import React from "react";
export const FootRightSide = () => {
  return (
    <div className="main-footer p-3 privacy-text">
      <a href="#!" target="_blank">
        <span className="col-sm-12">Privacy Policy</span>
      </a>
      <a href="#!" target="_blank">
        <span className="col-sm-12 ps-3">Terms &amp; Conditions</span>
      </a>

      <span className="footer-copy float-end pe-3">
        All Rights Reserved GMT {new Date().getFullYear()}
      </span>
    </div>
  );
};
