import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { HandlePaymentFetch } from "../../actions/payment_actions";
import { Row, Col, Button, Card, CardBody } from "reactstrap";

function Welcome({session_data,dispatch}) {
  useEffect(() => {
    if (session_data.active_company) {
      dispatch(HandlePaymentFetch(session_data.active_company.id));
    }
  }, [dispatch,session_data]);

  return (
    <>
      <div className="p-5 ">
        <Row>
          <h2 className="doc-heade ml-1">Welcome to SentePay API Platform </h2>
        </Row>
        <Row className="mt-4">
          <Col lg={12} sm={12} md={12}>
            <span>
              {" "}
              SentePay is committed to providing reliable payments and
              collections in a fast, secure way by letting clients sit back and
              focus on their business marketing while we do the hard work.
            </span>
          </Col>
          <Col lg={4} md={4} sm={12} className="my-2">
            <Button type="button" className="upgrade-btn btn-outline">
              <Link to="/api-Sandbox">
                {" "}
                <span className="pay-btn-text ">SandBox</span>
              </Link>{" "}
            </Button>
          </Col>
        </Row>
      </div>
      <div className="p-5">
        <Row>
          <h3 className="doc-head ml-1">Our plans </h3>
          <hr />
        </Row>
        <Row>
          <Col lg={12} sm={12} md={12}>
            <span>
              You are currently running on a free limited account but you can
              subscribe to our plans so as you get full access to SentePay.
            </span>
          </Col>
          <Col lg={4} md={4} sm={12} className="my-2">
            <Card>
              <div
                className=" mb-3 shadow bg-light"
                style={{
                  maxWidth: "18rem",
                  borderRadius: 10,
                  border: '"2px solid #2580F8"',
                }}
              >
                <div className="mr-4 mt-4">
                  <img
                    className="float-end"
                    src="./images/check-icon.svg"
                    height="20px"
                    width="20px"
                    alt=""
                  />
                </div>
                <CardBody>
                  <h5 className="card-title text-center subscription-header">
                    FREE
                  </h5>
                  <span className="d-flex justify-content-center subscription-subheader">
                    Subscribed
                  </span>
                  <hr />
                  <div className="card-text d-flex justify-content-center ml-5">
                    <ul className=" list-unstyled ">
                      <li className="subscription-list">Hours Support</li>
                      <li className="subscription-list">All Over the World</li>
                      <li className="subscription-list">Customer Management</li>
                      <li className="subscription-list">Unlimited</li>
                      <li className="subscription-list">Limited users</li>
                    </ul>
                  </div>
                </CardBody>
              </div>
            </Card>
          </Col>
          <Col lg={4} md={4} sm={12} className="my-2">
            <Card
              className=" mb-3 shadow bg-light"
              style={{
                maxWidth: "18rem",
                borderRadius: 10,
                border: '"2px solid #2580F8"',
              }}
            >
              <CardBody>
                <span className="d-flex justify-content-center sub-period mb-3">
                  Monthly <span className="dropdown-toggle" />{" "}
                </span>
                <h5 className="card-title text-center subscription-header-blue">
                  Basic
                </h5>
                <span className="d-flex justify-content-center subscription-subheader-blue">
                  UGX 500,000
                </span>
                <hr />
                <div className="card-text d-flex justify-content-center ml-5">
                  <ul className=" list-unstyled ">
                    <li className="subscription-list">24 Hours Support</li>
                    <li className="subscription-list">All Over the World</li>
                    <li className="subscription-list">Customer Management</li>
                    <li className="subscription-list">Unlimited</li>
                    <li className="subscription-list">Limited users</li>
                  </ul>
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col lg={4} md={4} sm={12} className="my-2">
            <Card>
              <div
                className="mb-3 shadow bg-light"
                style={{
                  maxWidth: "18rem",
                  borderRadius: 10,
                  border: '"2px solid #2580F8"',
                }}
              >
                <CardBody>
                  <span className="d-flex justify-content-center sub-period mb-3">
                    Monthly <span className="dropdown-toggle" />{" "}
                  </span>
                  <h5 className="card-title text-center subscription-header-blue">
                    PREMIUM
                  </h5>
                  <span className="d-flex justify-content-center subscription-subheader-blue">
                    UGX 1,000,000
                  </span>
                  <hr />
                  <div className="card-text d-flex justify-content-center ml-5">
                    <ul className=" list-unstyled ">
                      <li className="subscription-list">Hours Support</li>
                      <li className="subscription-list">All Over the World</li>
                      <li className="subscription-list">Customer Management</li>
                      <li className="subscription-list">Unlimited</li>
                      <li className="subscription-list">Limited users</li>
                    </ul>
                  </div>
                </CardBody>
              </div>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
}

const mapWelcomePageSateToProps = (state) => {
  return {
    session_data: state.authState.user_obj,
    payment_list: state.paymentState.payment_list,
  };
};

const WelcomePage = connect(mapWelcomePageSateToProps)(Welcome);
export default WelcomePage;
