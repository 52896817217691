import Axios from "axios";
// import { toastr } from "react-redux-toastr";
import { AxiosHeaders } from "../../constants";
import { base_url } from "../../constants/constants";

export const HandleDocumentTypeInput = (e) => {
  const { value, name } = e.target ? e.target : e;
  return {
    type: "DOCUMENT_TYPE_INPUT_CHANGE",
    changedField: { [name]: value },
  };
};

export const clearDocumentTypeForm = (e) => {
  e.preventDefault();
  return { type: "CLEAR_DOCUMENT_TYPE_FORM" };
};

export const DocumentTypeFetchSuccess = (payload, msg) => {
  // toastr.success("Success!",message);
  return { type: "FETCH_DOCUMENT_TYPE_SUCCESS", payload };
};
export const DocumentTypeAddSuccess = (new_record, update = false) => {
  // console.log(new_record)
  if (update) {
    // toastr.success("The Document Type has been Updated  Successfuly");
  } else {
    // toastr.success("The Document Type has been created  Successfuly");
  }
  return { type: "ADD_DOCUMENT_TYPE_SUCCESS", new_record };
};
export const DocumentTypeDeleteSuccess = (msg, record_id) => {
  // toastr.success("Document Type Has been deleted successfully");
  return { type: "DELETE_DOCUMENT_TYPE_SUCCESS", record_id };
};
export const DocumentTypeReqError = (msg) => {
  // toastr.error(msg);
  return { type: "DOCUMENT_TYPE_ERROR" };
};

export const editDocumentType = (obj) => {
  //console.log(obj);
  return { type: "EDIT_DOCUMENTTYPE", current_document_type_obj: obj };
};
export const HandleDocumentTypeSubmit = (form_data) => (dispatch) => {
  const url = form_data.id
    ? base_url("doc_type/") + form_data.id
    : base_url("doc_type");

  const req_type = form_data.id ? Axios.put : Axios.post;
  req_type(url, form_data, { headers: AxiosHeaders })
    .then((response) => {
      if (response.status === 201 || response.status === 200) {
        if (form_data.id) {
          dispatch(DocumentTypeAddSuccess(response.data, true));
        } else {
          dispatch(DocumentTypeAddSuccess(response.data));
        }
      }
    })
    .catch((error) => {
      if (error.response) {
        console.log(error.response);
      } else if (error.request) {
        console.log(error.request);
      } else {
        // toastr.error("Please check your network and try again");
      }
    });

  return null;
};
export const HandleDocumentTypeDelete = (record_id) => (dispatch) => {
  const url = base_url("doc_type/" + record_id);
  Axios.delete(url, { headers: AxiosHeaders })
    .then((response) => {
      if (response.status === 201 || response.status === 200) {
        dispatch(DocumentTypeDeleteSuccess(response.message, record_id));
      }
    })
    .catch((error) => {
      if (error.response) {
        console.log(error.response);
      } else if (error.request) {
        console.log(error.request);
      } else {
        // toastr.error("Please check your network and try again");
      }
    });

  return null;
};
export const HandleDocumentTypeFetch = () => (dispatch) => {
  const url = base_url("doc_type");
  Axios.get(url, { headers: AxiosHeaders })
    .then((response) => {
      if (response.status === 200) {
        dispatch(DocumentTypeFetchSuccess(response.data));
      }
    })
    .catch((error) => {
      if (error.response) {
        console.log(error.response);
      } else if (error.request) {
        console.log(error.request);
      } else {
        // toastr.error("Please check your network and try again");
      }
    });

  return null;
};
