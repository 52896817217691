import React, { useEffect } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { verify, ResendEmail } from "../../actions/authAction";
import EmailSent from "./mailSent";
import { Row, Col, Card, CardBody, Button } from "reactstrap";

const VerifyEmail = ({ session_data, link_valid, email_sent, loader, match, dispatch }) => {
  const {params} = match;
  useEffect(() => {
    if (params && params.tokenId && params.token) {
      //make a call to the verification service
      //with a success, the email verified field will be updated
      dispatch(verify(params.tokenId, params.token));
    }
  }, [dispatch,params]);

  return (
    <Col md={10} sm={12} lg={10}>
      <Row>
        <Col md={4} className="offset-md-4">
          <Card className=" mt-3">
            <CardBody>
              <h4 className="text-muted">Email Verification</h4>
              {!link_valid ? (
                <>
                  {!email_sent ? (
                    <p className="card-text">
                      The verification link is invalid or expired,
                      <br />
                      Please click the button below to resend the verification
                      email.
                    </p>
                  ) : (
                    <EmailSent />
                  )}
                  {session_data.email_verified_at == null && (
                    <Button
                      disabled={loader}
                      onClick={() => dispatch(ResendEmail())}
                      type="button"
                      className="col-md-6 w-100 pay-btn text-white"
                    >
                      Resend Email
                    </Button>
                  )}
                </>
              ) : (
                <p className="card-text text-default">Verifying email...</p>
              )}
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Col>
  );
};

const mapStateToProps = (state) => ({
  session_data: state.authState.user_obj,
  link_valid: state.authState.link_valid,
  email_sent: state.authState.email_sent,
  loader: state.authState.loader,
});
const MailVerified = withRouter(connect(mapStateToProps)(VerifyEmail));

export default MailVerified;
