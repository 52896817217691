import DashboardHome from "./components/app/dashboard";
import { LoginUser } from "./components/auth/login";
import { RegisterUser } from "./components/auth/signUp";
import NotFound from "./components/app/notFound";
import ForgotPass from "./components/auth/forgotPassword";
import MailSuccess from "./components/auth/mailSent";
import ResetPass from "./components/auth/resetPassword";
import PwdSuccess from "./components/auth/pwdSuccess";
import { FiredUpMerchantTicketComp } from "./components/app/support_tickets/component";
import { FAQ } from "./components/app/faq/component";
import { Reports } from "./components/app/reports/component";
import { FiredUpAccountsettings } from "./components/app/account_settings/component";
import CompanyRoutes from "./components/app/companies/company_routes";
import { WithdrawHistory } from "./components/app/withdraw_history/component";
import MailVerified from "./components/auth/emailVerified";
import WelcomePage from "./components/app/new_user";
import { PayReqReduxComp } from "./components/app/payment_links/component";
import { CompanyCardSubComp } from "./components/app/subscription/subscription_cards";
import { SingleReqReduxComp } from "./components/app/payment_links/singlePaymentLink";
import TransactionsComp from "./components/app/products/component";

const allRoutes = [
  {
    name: "Dashboard",
    path: "/",
    component: DashboardHome,
    exact: true,
  },
  {
    name: "Dashboard",
    path: "/dashboard",
    component: DashboardHome,
  },
  {
    name: "WelcomePage",
    path: "/welcome",
    component: WelcomePage,
  },
  {
    name: "Support Tickets",
    path: "/support_tickets",
    component: FiredUpMerchantTicketComp,
  },
  {
    name: "Account Settings",
    path: "/account_settings",
    component: FiredUpAccountsettings,
  },
  {
    name: "Companies",
    path: "/company",
    component: CompanyRoutes,
  },
  {
    name: "Withdraw History",
    path: "/withdraw_history",
    component: WithdrawHistory,
  },
  {
    name: "Transactions",
    path: "/transactions",
    component: TransactionsComp,
  },
  {
    name: "FAQ",
    path: "/faq",
    component: FAQ,
  },
  {
    name: "Subscriptions",
    path: "/subscriptions",
    component: CompanyCardSubComp,
  },
  {
    name: "Payment Requests",
    path: "/PaymentRequests",
    component: PayReqReduxComp,
  },
  {
    name: "SingleRequests",
    path: "/singlerequests",
    component: SingleReqReduxComp,
  },
  {
    name: "Reports",
    path: "/reports",
    component: Reports,
  },
  {
    name: "verifymail",
    exact: true,
    path: "/verifymail/:tokenId/:token",
    component: MailVerified,
  },
  {
    name: "verifymail",
    path: "/verifymail",
    component: MailVerified,
  },
];

export const authRoutes = [
  {
    name: "Login",
    path: "/login",
    component: LoginUser,
  },
  {
    name: "ForgotPassword",
    path: "/forgotPassword",
    component: ForgotPass,
  },
  {
    name: "MailSuccess",
    path: "/mailSuccess",
    component: MailSuccess,
  },
  {
    name: "NotFound",
    path: "/notFound",
    component: NotFound,
  },
  {
    name: "SignUp",
    path: "/sign_up",
    component: RegisterUser,
  },
  {
    name: "resetPassword",
    path: "/reset_pwd/:token",
    component: ResetPass,
  },
  {
    name: "pwdSuccess",
    path: "/pwdSuccess",
    component: PwdSuccess,
  },
];

export default allRoutes;
