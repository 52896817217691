const CountryInitailState = {
  country_list: [],
};

export const CountryReducer = (prevState = CountryInitailState, action) => {
  switch (action.type) {
    case "FETCH_COUNTRY_SUCCESS":
      return { ...prevState, country_list: action.payload.data };
    case "FETCH_COUNTRY_FAIL":
      return { ...prevState };
    default:
      return { ...prevState };
  }
};
