
const currencyformstate  = {
    currency_name : '',
    currency_code :'',
    description : ''
}

const CurrencyInitailState  = {
    currency_list  : [],
    current_currency_obj : currencyformstate,
    is_processing : false

}

export const CurrencyReducer = (prevState = CurrencyInitailState , action ) =>{
    let form_state = prevState.current_currency_obj
    let new_formState
    switch(action.type){
        case  'CREATE_CURRENCY_SUCCESS' : 
        let currency_new = true
        let currency_list = prevState.currency_list.map(currency=>{
            if(currency.id===action.new_currency.id){
                currency_new = false
                return action.new_currency
            }else{
                return currency
            }
        }
        )
        currency_new && currency_list.push(action.new_currency)
        const new_state = {...prevState, currency_list,}
        if(currency_new){
            new_state.current_currency_obj = currencyformstate
        }
        return new_state;
        case  'CURRENCY_INPUT_CHANGE' :
            new_formState = {...form_state, ...action.changedField}
            return {...prevState, current_currency_obj:new_formState};
        case  'CLEAR_CURRENCY_FORM':
            return {...prevState,current_currency_obj : currencyformstate }
        case 'EDIT_CURRENCY':
            new_formState = {...action.current_currency_obj}
            return {...prevState, current_currency_obj:new_formState};
        case 'DELETE_CURRENCY_SUCCESS':
            let new_list = prevState.currency_list.filter(current_rec=>current_rec.id!==action.record_id)
            return {...prevState, currency_list:new_list};
        case 'FETCH_CURRENCY_SUCCESS':
                return {...prevState, currency_list:action.payload.data,}
        case 'CURRENCY_ERROR':
            return {...prevState}
        case 'CURRENCY_REQUEST_PROCESSING':
            return {...prevState,is_processing : action.value}
        default :
        return  {...prevState}
    }
}
