import React, { useEffect, useRef, useState } from "react";
import CopyToClipboard from "react-copy-to-clipboard";
import { connect } from "react-redux";
import moment from "moment";
import { currFormat } from "../../../constants/GenHelpers";
import {
  clearPayReqForm,
  editPayReq,
  HandlePayReqDelete,
  HandlePayReqFetch,
  HandlePayReqInput,
  HandlePayReqSubmit
} from "../../../actions/PayReqs";
import { encData } from "../../../constants";
import TblComp from "../../../datatable";
import { AddLink } from "./add_link";
import { EmbeddedCode } from "./embedCode";
import { useSelector } from "react-redux";
import { Button } from "reactstrap";
import DeleteModal from "../../../constants/deleteModal";

export const PaymentLinks = (props) => {
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);
  const [deleteModal, setDeleteModal] = useState(false);
  const deleteToggle = () => setDeleteModal(!deleteModal);
  const dataFetchedRef = useRef(false);
  const [selected, setSelected] = useState(false);

  const { isPRProgess } = useSelector((state) => state.PayReqState);
  const [id, setId] = useState();

  const CopyLink = (recordId) => {
    setId(recordId);
    navigator.clipboard.writeText("https://pay.sentepay.com/" + recordId);
  };

  useEffect(() => {
    //we assume that we are reaching here only when the active company account exists
    if (dataFetchedRef.current) return;
    dataFetchedRef.current = true;
    props.active_company.default_company_account &&
      props.fetchPayReqs(props.active_company.default_company_account.id);
  }, [props]);

  return (
    <div className="content-box-header panel-heading table-container">
      <Button
        className="payment-btn my-3 text-white"
        onClick={() => [props.editHandler(null), toggle()]}
      >
        New Request
      </Button>
      <AddLink
        submitHandler={props.submitHandler}
        toggle={toggle}
        modal={modal}
        comp_obj={props.active_company}
      />

      <DeleteModal
        modal={deleteModal}
        toggle={deleteToggle}
        name="Payment Link"
        handleDelete={() => props.deleteHandler(selected, deleteToggle)}
        is_processing={isPRProgess}
      />

      <TblComp
        columns={[
          {
            Header: "Amount",
            accessor: "amount",
            filter: "fuzzyText",
            Cell: ({ value }) => (value ? currFormat(value * 1) : ""),
          },
          {
            id: "start_time", // Required because our accessor is not a string
            Header: "Activated",
            accessor: "start_time",
            filter: "fuzzyText",
            Cell: ({ value }) =>
              value ? moment(value).format("D-M-YYYY h:mm a") : "",
          },
          {
            id: "end_time", // Required because our accessor is not a string
            Header: "Expires",
            accessor: "end_time",
            filter: "fuzzyText",
            Cell: ({ value }) =>
              value ? moment(value).format("D-M-YYYY h:mm a") : "",
          },
          {
            Header: "Purpose",
            accessor: "purpose",
            filter: "fuzzyText",
          },
          {
            id: "url", // Required because our accessor is not a string
            Header: "Copy Url",
            maxWidth: 150,
            accessor: (full) => (
              <Button
                color="primary"
                size="sm"
                onClick={() => CopyLink(full.req_id)}
              >
                {id === full.req_id ? "Copied!" : "Copy"}
              </Button>
            ),
          },
          {
            id: "code", // Required because our accessor is not a string
            Header: "Embed Code",
            maxWidth: 150,
            accessor: (full) => (
              <CopyToClipboard
                className="btn btn-sm btn-primary  pl-3 text-whte"
                text={EmbeddedCode(
                  encData({
                    amount: full.amount,
                    token: full.token,
                    desc: full.purpose,
                  })
                )}
              >
                <Button size="sm" className="text-whit ml-3">
                  Copy
                </Button>
              </CopyToClipboard>
            ),
          },
          {
            id: "actions", // Required because our accessor is not a string
            Header: "Actions",
            maxWidth: 150,
            accessor: (full) => (
              <>
                <Button
                  color="warning"
                  className="mx-1"
                  size="sm"
                  onClick={(e) => [props.editHandler(full), toggle()]}
                >
                  <img
                    src="/images/edit.svg"
                    height="15px"
                    width="15px"
                    className="ms-1"
                    alt="edit"
                  />
                </Button>

                <Button
                  color="danger"
                  size="sm"
                  className="mx-1"
                  onClick={(e) => [deleteToggle(), setSelected(full.req_id)]}
                >
                  <img
                    src="/images/deleted.svg"
                    height="15px"
                    width="15px"
                    alt="delete"
                    className="ms-1"
                  />
                </Button>
              </>
            ),
          },
        ]}
        data={props.pay_req_list}
      />
    </div>
  );
};

// connect the store for the PayReq dispatches and state
const mapPayReqStateToProps = (state) => {
  return {
    current_pay_req_obj: state.PayReqState.current_pay_req_obj,
    active_company: state.authState.user_obj.active_company,
    pay_req_list: state.PayReqState.pay_req_list,
  };
};
const mapPayReqsDispatchToProps = (dispatch) => {
  return {
    handleInput: (e) => dispatch(HandlePayReqInput(e)),
    clearFormHandler: (e) => dispatch(clearPayReqForm(e)),
    submitHandler: (form_obj, url_id, e, toggle) =>
      dispatch(HandlePayReqSubmit(form_obj, url_id, e, toggle)),
    fetchPayReqs: (url_id) => dispatch(HandlePayReqFetch(url_id)),
    editHandler: (selected_row) => dispatch(editPayReq(selected_row)),
    deleteHandler: (selected_id, toggle) =>
      dispatch(HandlePayReqDelete(selected_id, toggle)),
    activeLink: (selected_id) => dispatch(HandlePayReqDelete(selected_id)),
  };
};
export const PayReqReduxComp = connect(
  mapPayReqStateToProps,
  mapPayReqsDispatchToProps
)(PaymentLinks);
