import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { HandleLogout } from "../../actions/authAction";
import { ActivateCompany } from "../../actions/companyActions";
import { Button, Dropdown, DropdownMenu, DropdownToggle } from "reactstrap";
import sentepayLogo from "../../assets/img/sentepay-logo.png";
import { Col, Row } from "reactstrap";

export const DropdownItem = (props) => {
  const { item_name, path, onClick } = props;

  return (
    <Link
      className={`mx-3 ${
        props.location_obj.pathname &&
        props.location_obj.pathname.includes(path)
          ? "active-link"
          : ""
      }`}
      to={path}
      onClick={typeof onClick === "function" ? onClick : (e) => {}}
    >
      {item_name}
    </Link>
  );
};

export const TopNav = (props) => {
  const [selected_company, SwitchedCompany] = useState(0);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggle = () => setDropdownOpen(!dropdownOpen);

  useEffect(() => {
    props.session_data.active_company != null &&
      SwitchedCompany(props.session_data.active_company.id);
  }, [props.session_data.active_company]);

  const changeCompany = (e) => {
    const { value: selected_company_id } = e.target;
    if (selected_company_id) {
      SwitchedCompany(selected_company_id);
      props.dispatch(ActivateCompany(selected_company_id));
    }
  };

  return (
    <div className="header  sticky-top header-shadow">
      <div className="container-fluid">
        <Row>
          <Col md={3} lg={3} sm={12}>
            <div className="logo">
              <h1 className="ms-4">
                <Link to="/">
                  <img alt="" src={sentepayLogo} className="logo-style" />
                </Link>
              </h1>
            </div>
          </Col>

          <Col lg={9} md={9} sm={12}>
            <div className="row float-end me-5">
              <div role="banner">
                <Dropdown isOpen={dropdownOpen} toggle={toggle}>
                  <DropdownToggle
                    tag="span"
                    onClick={toggle}
                    data-toggle="dropdown"
                    aria-expanded={dropdownOpen}
                  >
                    <div className="nav-link user-name pointer-cursor mt-2">
                      <div className="custom-nav">
                        <div className="custom-nav-div">
                          <div
                            style={{
                              display: "inline-block",
                              position: "relative",
                              outline: "0px",
                            }}
                          >
                            <span className="custom-image-span">
                              <img
                                className="avatar-img img-thumbnail mr-1 custom-image"
                                src={
                                  "https://cdn3.iconfinder.com/data/icons/vector-icons-6/96/256-512.png"
                                }
                                alt=""
                              />
                            </span>
                          </div>
                        </div>
                        <div>
                          <div>
                            {props.session_data.firstname}{" "}
                            {props.session_data.lastname}
                          </div>
                          <small> {props.session_data.email}</small>
                        </div>
                      </div>
                    </div>
                  </DropdownToggle>
                  <DropdownMenu>
                    <div className="nav-link user-name header-dropdown">
                      <div className="custom-div">
                        <span className="custom-div-span">
                          <ul className="nav">
                            <DropdownItem
                              path={"/account_settings"}
                              item_name={"Account Settings"}
                              location_obj={props.location_obj}
                            />
                          </ul>
                        </span>
                      </div>
                      <hr />

                      <ul className="nav">
                        <li className="nav-link user-name">
                          Subscription Plans
                        </li>
                      </ul>

                      <li className="dropdown-container pointer-cursor">
                        <span className="avatar-img-container">
                          <b className="avatar-img-text">
                            {props.default_company &&
                            props.session_data.active_company.active_plan
                              ? props.session_data.active_company.active_plan
                                  .subscription_plan.subscription_plan_name
                              : "FREE PLAN"}
                          </b>
                        </span>
                      </li>

                      <li className="dropdown-container pointer-cursor">
                        <Link to={"/subscriptions"}>
                          <b className="change-plan-text">Change Plan</b>
                        </Link>
                      </li>

                      <hr />
                      <ul className="nav">
                        <DropdownItem
                          path={"/company"}
                          item_name={"My Companies"}
                          location_obj={props.location_obj}
                        />
                      </ul>

                      {props.session_data.active_company != null && (
                        <div className="col-auto d-flex mt-2 px-2">
                          <select
                            onChange={(e) => {
                              e.preventDefault();
                              changeCompany(e);
                            }}
                            value={selected_company}
                            className="form-control form-control-sm pe-3 "
                          >
                            {props.company_list.length &&
                              props.company_list.map((company, idx) => {
                                if (company.status === "approved") {
                                  return (
                                    <option
                                      key={`option${idx}`}
                                      className="p-2"
                                      value={company.id}
                                    >
                                      {company.company_name}
                                    </option>
                                  );
                                } else {
                                  return "";
                                }
                              })}
                            {props.company_list.length === 0 && (
                              <option>No Company</option>
                            )}
                          </select>
                        </div>
                      )}
                      <hr />
                      <Button
                        color="link"
                        onClick={(e) => [
                          e.preventDefault(),
                          props.dispatch(HandleLogout()),
                        ]}
                      >
                        <div className="custom-div">
                          <span className="custom-div-span">
                            <span
                              className="logout-style"
                              data-item-title="true"
                            >
                              Log out
                            </span>
                          </span>
                        </div>
                      </Button>
                    </div>
                  </DropdownMenu>
                </Dropdown>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};
