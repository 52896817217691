import Axios from "axios";
// import { toastr } from "react-redux-toastr";
import { AxiosHeaders } from "../../constants";
import { base_url } from "../../constants/constants";

export const HandleContactTypeInput = (e) => {
  const { value, name } = e.target ? e.target : e;
  return { type: "CONTACT_TYPE_INPUT_CHANGE", changedField: { [name]: value } };
};

export const clearContactTypeForm = (e) => {
  e.preventDefault();
  return { type: "CLEAR_CONTACT_TYPE_FORM" };
};

export const ContactTypeFetchSuccess = (payload, msg) => {
  // toastr.success("Success!",message);
  return { type: "FETCH_CONTACT_TYPE_SUCCESS", payload };
};
export const ContactTypeAddSuccess = (new_record, update = false) => {
  if (update) {
    // toastr.success("The Contact Type has been Updated  Successfuly");
  } else {
    // toastr.success("The Contact Type has been created  Successfuly");
  }
  // console.log(new_record)
  return { type: "ADD_CONTACT_TYPE_SUCCESS", new_record: new_record };
};
export const ContactTypeDeleteSuccess = (msg, record_id) => {
  // toastr.success("The Contact type was deleted successfuly");
  return { type: "DELETE_CONTACT_TYPE_SUCCESS", record_id };
};
export const ContactTypeReqError = (msg) => {
  // toastr.error(msg);
  return { type: "CONTACT_TYPE_ERROR" };
};

export const editContactType = (obj) => {
  return { type: "EDIT_CONTACT_TYPE", current_contact_type_obj: obj };
};
export const HandleContactTyepSubmit = (form_data) => (dispatch) => {
  const url = form_data.id
    ? base_url("contact_type/" + form_data.id)
    : base_url("contact_type");

  const req_type = form_data.id ? Axios.put : Axios.post;
  req_type(url, form_data, { headers: AxiosHeaders })
    .then((response) => {
      if (response.status === 201 || response.status === 200) {
        if (form_data.id) {
          dispatch(ContactTypeAddSuccess(response.data, true));
        } else {
          dispatch(ContactTypeAddSuccess(response.data));
        }
      }
    })
    .catch((error) => {
      if (error.response) {
        console.log(error.response);
      } else if (error.request) {
        console.log(error.request);
      } else {
        // toastr.error("Please check your network and try again");
      }
    });

  return null;
};
export const HandleContactTypeDelete = (record_id) => (dispatch) => {
  const url = base_url("contact_type/" + record_id);
  Axios.delete(url, { headers: AxiosHeaders })
    .then((response) => {
      if (response.status === 201 || response.status === 200) {
        dispatch(ContactTypeDeleteSuccess(response.message, record_id));
      }
    })
    .catch((error) => {
      if (error.response) {
        console.log(error.response);
      } else if (error.request) {
        console.log(error.request);
      } else {
        // toastr.error("Please check your network and try again");
      }
    });

  return null;
};
export const HandleContactTypeFetch = () => (dispatch) => {
  const url = base_url("contact_type");
  Axios.get(url, { headers: AxiosHeaders })
    .then((response) => {
      if (response.status === 200) {
        // console.log(response);
        dispatch(ContactTypeFetchSuccess(response.data));
      }
    })
    .catch((error) => {
      if (error.response) {
        console.log(error.response);
      } else if (error.request) {
        console.log(error.request);
      } else {
        // toastr.error("Please check your network and try again");
      }
    });

  return null;
};
