import { decData, encData } from "../constants";
const PayReqFormState = {
  id: "",
  amount: "",
  purpose: "",
  start_time: "",
  end_time: "",
  min_amount: "",
  max_amount: "",
};
const PayReqInitState = {
  pay_req_list: [],
  single_pay_req: [],
  total:0,
  pageCount:0,
  single_record: false,
  current_pay_req_obj: PayReqFormState,
  isPRProgess: false,
  pay_link_attr: {},
};
export const PayReqReducer = (prevState = PayReqInitState, action) => {
  const form_state = prevState.current_pay_req_obj;
  let new_formState;
  switch (action.type) {
    case "PAYREQ_ERROR":
      return { ...prevState };
    case "SET_ACTIVE_PAYREQ":
      return { ...prevState, active_company_id: action.payload.active_company };
    case "SET_API_KEY":
      return { ...prevState, api_key: action.payload };
    case "FETCH_PAYREQ_SUCCESS":
      if (action.isPayReq) {
        return { ...prevState, single_record: action.payload };
      } else {
        return { ...prevState, pay_req_list: action.payload.data };
      }
    case "PAYREQ_INPUT_CHANGE":
      if (action.changedField.company_id) {
        return { ...prevState, companyChanged: true };
      }
      new_formState = { ...form_state, ...action.changedField };
      return { ...prevState, current_pay_req_obj: new_formState };
    case "FETCH_SINGLE_PAY_SUCCESS":
      return { ...prevState, single_pay_req: action.payload };
    case "RESET_PAYREQ":
      return { ...prevState, companyChanged: false };
    case "PAYREQ_COPY_SUCCESS":
      return { ...prevState, pay_link_attr: action.payload };
    case "CLEAR_PAYREQ_FORM":
      return { ...prevState, current_pay_req_obj: PayReqFormState };
    case "EDIT_PAYREQ":
      new_formState = { ...action.current_pay_req_obj };
      return { ...prevState, current_pay_req_obj: new_formState };
    case "DELETE_PAYREQ_SUCCESS":
      let new_list = prevState.pay_req_list.filter(
        (current_rec) => current_rec.req_id !== action.record_id
      );
      return { ...prevState, pay_req_list: new_list };

    case "ADD_PAYREQ_SUCCESS":
      let company_new = true;
      let pay_req_list = prevState.pay_req_list.map((company) => {
        if (company.id === action.new_record.id) {
          company_new = false;
          return action.new_record;
        } else {
          return company;
        }
      });
      company_new && pay_req_list.push(action.new_record);
      const new_state = { ...prevState, pay_req_list };
      if (company_new) {
        new_state.current_pay_req_obj = PayReqFormState;
      }
      return new_state;
    case "PAYREQ_IS_PROGRESS":
      return { ...prevState, isPRProgess: action.isProgress };

    case "PAYREQ_IS_COPING":
      return { ...prevState, isCoping: action.isCoping };

    case "PAYREQ_SUCCESS_COPY":
      return { ...prevState, pay_link_attr: action.payload };

    case "ACTIVATE_MERCHANT_PAYREQ":
      let user = decData(sessionStorage.getItem("user"));
      user.active_company = action.active_company;
      sessionStorage.setItem("user", encData(user));
      return { ...prevState, active_company_id: action.active_company };
    default:
      return prevState;
  }
};
