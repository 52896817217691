import React from "react";

import { NavLink, Route, Switch } from "react-router-dom";
import { Nav, NavItem, Card } from "reactstrap";
import { CompanyContactReduxComp } from "./company_contacts";
import { CompanyProfileReduxComp } from "./company_profile";
import { CompanyDocReduxComp } from "../documents";

export const CompanyDetails = (props) => {
  const { match } = props;
  const company_id = match.params.id;

  const parent_url = `/company`;
  return (
    <>
      <Card>
        <Nav pills>
          <NavItem className="nav-item nav-link active">
            <NavLink
              exact
              activeClassName="btn mb-1 ticket-tab-active"
              className="nav-item nav-link"
              to={`${parent_url}/${company_id}/profile`}
            >
              Profile
            </NavLink>
          </NavItem>
          <NavItem className="nav-item nav-link">
            <NavLink
              exact
              activeClassName="btn mb-1 ticket-tab-active"
              className="nav-item nav-link"
              to={`${parent_url}/${company_id}/contacts`}
            >
              Contacts
            </NavLink>
          </NavItem>
          <NavItem className="nav-item nav-link">
            <NavLink
              exact
              activeClassName="btn mb-1 ticket-tab-active"
              className="nav-item nav-link"
              to={`${parent_url}/${company_id}/documents`}
            >
              Documents
            </NavLink>
          </NavItem>
        </Nav>
      </Card>
      <Switch>
        <Route
          exact
          path={`${parent_url}/:id/contacts`}
          component={CompanyContactReduxComp}
        />
        <Route
          path={`${parent_url}/:id/documents`}
          component={CompanyDocReduxComp}
        />

        <Route component={CompanyProfileReduxComp} />
      </Switch>
    </>
  );
};
