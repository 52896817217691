// import { toastr } from "react-redux-toastr";
import { allconstants } from "../constants/constants";
import { HandleCompanyFetch, updateBalance } from "./companyActions";

export const HandleCompanySubInput = (e) => {
  const { value, name } = e.target ? e.target : e;
  return { type: "COMPANY_SUB_INPUT_CHANGE", changedField: { [name]: value } };
};

export const clearCompanySubForm = (e) => {
  e.preventDefault();
  return { type: "CLEAR_COMPANY_SUB_FORM" };
};

export const CompanySubFetchSuccess = (payload, sup_plans = false) => {
  return { type: "FETCH_COMPANY_SUB_SUCCESS", payload, sup_plans };
};
export const CompanySubAddSuccess = (new_record) => {
  // toastr.success("Howry!", "Subscription is successful!");
  return { type: "ADD_COMPANY_SUB_SUCCESS", new_record };
};
export const CompanySubDeleteSuccess = (msg, record_id) => {
  // toastr.success("Howry!", msg);
  return { type: "DELETE_COMPANY_SUB_SUCCESS", record_id };
};
export const CompanySubReqError = (msg) => {
  // toastr.error("Whoops!", msg);
  return { type: "COMPANY_SUB_ERROR" };
};
export const HandleSubscriptionPlanFetch =
  (url_id = null) =>
  (dispatch) => {
    const url = url_id
      ? allconstants.apiurl + "subscription_plan/" + url_id
      : allconstants.apiurl + "subscription_plan";
    allconstants.axios
      .get(url, { headers: allconstants.headers })
      .then((response) => {
        if (response.status === 200) {
          dispatch(CompanySubFetchSuccess(response.data, true));
        }
      })
      .catch((error) => {
        if (error.response) {
          console.log(error.response);
        } else if (error.request) {
          console.log(error.request);
        } else {
          // toastr.error("Please check your network and try again");
        }
      });
  };

export const HandleCompanySubSubmit = (form_data, company_id) => (dispatch) => {
  if (
    form_data != null &&
    form_data.subscription_plan_id &&
    company_id != null
  ) {
    const url = allconstants.apiurl + "company/" + company_id + "/subplan";

    const req_type = form_data.id
      ? allconstants.axios.put
      : allconstants.axios.post;
    req_type(url, form_data, { headers: allconstants.headers })
      .then((response) => {
        if (response.status === 201 || response.status === 200) {
          dispatch(CompanySubAddSuccess(response.data));
          dispatch(HandleCompanyFetch(company_id, true));
        } else if (response.status === 400 && response.data.balance) {
          dispatch(updateBalance(response.data.balance));
        }
      })
      .catch((error) => {
        if (error.response) {
          console.log(error.response);
        } else if (error.request) {
          console.log(error.request);
        } else {
          // toastr.error("Please check your network and try again");
        }
      });
  }
};
export const HandleCompanySubDelete = (record_id) => (dispatch) => {
  const url = allconstants.apiurl + "subplan/" + record_id;

  allconstants.axios
    .delete(url, { headers: allconstants.headers })
    .then((response) => {
      if (response.status === 201 || response.status === 200) {
        dispatch(CompanySubDeleteSuccess(response.data.message, record_id));
      }
    })
    .catch((error) => {
      if (error.response) {
        console.log(error.response);
      } else if (error.request) {
        console.log(error.request);
      } else {
        // toastr.error("Please check your network and try again");
      }
    });
};
export const HandleCompanySubFetch = (url_id) => (dispatch) => {
  const url = allconstants.apiurl + "company/" + url_id + "/subplan";
  allconstants.axios
    .get(url, { headers: allconstants.headers })
    .then((response) => {
      if (response.status === 200) {
        dispatch(CompanySubFetchSuccess(response.data));
      }
    })
    .catch((error) => {
      if (error.response) {
        console.log(error.response);
      } else if (error.request) {
        console.log(error.request);
      } else {
        // toastr.error("Please check your network and try again");
      }
    });
};
