import React from "react";
import mailStyles from "../../assets/css/mailSent.module.css";

export const NoMail = () => {
  return (
    <>
      <div class="row1-container">
        <div className={mailStyles.box}>
          <h3>Didn't get the email?</h3>
          <p className="text-muted mt-2">
            If you don't see an email from us within 5 minutes, it could be
            because of any of the following
          </p>
          <ol className="text-left">
            <li className="welcome-note">
              The email might be in your spam folder. (If you use Gmail, please
              check your Promotions folder as well.)
            </li>
            <li className="mt-2 welcome-note">
              The email address you entered had a typo.
            </li>
            <li className="mt-2 welcome-note">
              You accidentally entered another email address. (Usually happens
              with auto-complete.)
            </li>
            <li className="mt-2 welcome-note">
              We can't deliver the email to this address. (Usually because of
              firewall or filter settings on your mail server.)
            </li>
          </ol>
        </div>
      </div>
    </>
  );
};
const MailSuccess = ({ type }) => {
  return (
    <div className={`bg-white login-wrapper w-75 mx-auto`}>
      <h3 className="text-center">Check Your mailbox</h3>
      <p className="text-center">
        {type === "reset"
          ? "We've sent an email to you with a link to reset your password."
          : " We've sent an email to you with a link to confirm your account."}
      </p>
      <NoMail />
    </div>
  );
};
export default MailSuccess;
