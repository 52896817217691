import React, { useEffect } from "react";
import MaskedInput from "react-maskedinput";
import { connect } from "react-redux";
import {
  clearCompanyContactForm,
  editCompanyContact,
  HandleCompanyContactDelete,
  HandleCompanyContactFetch,
  HandleCompanyContactInput,
  HandleCompanyContactSubmit,
} from "../../../../actions/company_contact_actions";
import { HandleContactTypeFetch } from "../../../../actions/types/contact_type_actions";
import { Actions, Select } from "../../../../constants/constants";
import TblComp from "../../../../datatable";
import {
  Form,
  FormGroup,
  Row,
  Col,
  ModalBody,
  Modal,
  ModalHeader,
  Button,
} from "reactstrap";

export const CompanyContacts = ({
  current_company_contact_obj: formState,
  company_contact_list,
  dispatch,
  match,
  contact_type_list,
}) => {
  const [modal, setModal] = React.useState(false);
  const toggle = () => setModal(!modal);
  useEffect(() => {
    contact_type_list.length === 0 && dispatch(HandleContactTypeFetch());
    company_contact_list.length === 0 &&
      dispatch(HandleCompanyContactFetch(match.params.id));
  }, [dispatch,contact_type_list.length,match.params.id,company_contact_list.length]);

  const CompanyTypes = (full) => {
    if (typeof contact_type_list !== "undefined") {
      if (
        contact_type_list.filter(
          (contact) => contact.id === full.contact_type_id
        )[0]
      ) {
        return contact_type_list.filter(
          (contact) => contact.id === full.contact_type_id
        )[0].title;
      }
    }
    return null;
  };

  return (
    <Row>
      <Col lg={12} md={12} sm={12}>
        <div className="content-box-header panel-heading table-container">
          <Modal isOpen={modal} toggle={toggle}>
            <ModalHeader toggle={toggle}>
              <span className="col-11">Add Contact</span>
            </ModalHeader>
            <ModalBody>
              <Form
                autoComplete="off"
                method="post"
                onSubmit={(e) => {
                  e.preventDefault();
                  dispatch(
                    HandleCompanyContactSubmit(formState, match.params.id)
                  );
                }}
              >
                <FormGroup>
                  <div className="container-fluid">
                    <Row className="mt-3">
                      <Col lg={12} sm={12} md={12}>
                        <label className="lead">Select Type</label>
                        <Select
                          name="contact_type_id"
                          placeholder="--Select type--"
                          index_key="id"
                          label_key="title"
                          value={formState.contact_type_id}
                          options={contact_type_list}
                          handleChange={(e) =>
                            dispatch(HandleCompanyContactInput(e))
                          }
                        />
                      </Col>
                      <Col lg={12} sm={12} md={12} className="mt-2">
                        <MaskedInput
                          alwaysShowMask
                          name="masked_contact"
                          mask={"+256(000) 000 - 000"}
                          // size={20}
                          showMask
                          maskChar="_"
                          className="form-control"
                          value={
                            formState.id
                              ? formState.contact_details
                              : formState.masked_contact
                          }
                          onChange={(e) =>
                            dispatch(HandleCompanyContactInput(e))
                          }
                          autoComplete="off"
                        />
                      </Col>
                    </Row>
                  </div>

                  <div className="mt-3">
                    <Button type="submit" size="sm" className="pay-btn" block>
                      <span className="pay-btn-text ">Save</span>
                    </Button>
                  </div>
                </FormGroup>
              </Form>
            </ModalBody>
          </Modal>

          <Button
            className="payment-btn my-3 text-white"
            onClick={(e) => [
              dispatch(clearCompanyContactForm(e)),
              toggle(),
            ]}
          >
            Add Contact
          </Button>
          <TblComp
            columns={[
              {
                id: "type", // Required because our accessor is not a string
                Header: "Contact Type",
                maxWidth: 100,
                accessor: (full) => CompanyTypes(full),
              },
              {
                Header: "Contact",
                accessor: "contact_details",
                filter: "fuzzyText",
              },
              {
                id: "actions", // Required because our accessor is not a string
                Header: "Actions",
                maxWidth: 100,
                accessor: (full) => (
                  <Actions
                    editHandler={(selected_row) =>
                      dispatch(editCompanyContact(selected_row))
                    }
                    data_item={full}
                    modal_id="company_contact-modal"
                    deleteHandler={(rec_id) =>
                      dispatch(HandleCompanyContactDelete(rec_id))
                    }
                  />
                ),
              },
            ]}
            data={
              typeof company_contact_list !== "undefined"
                ? company_contact_list
                : []
            }
          />
        </div>
      </Col>
    </Row>
  );
};
// connect the store for the company contacts dispatches and state
const mapCompanyContactStateToProps = (state) => {
  return {
    current_company_contact_obj:
      state.companyContactsState.current_company_contact_obj,
    contact_type_list: state.contactTypeState.contact_type_list,
    company_contact_list: state.companyContactsState.company_contact_list,
  };
};
export const CompanyContactReduxComp = connect(mapCompanyContactStateToProps)(
  CompanyContacts
);
