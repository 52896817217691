import React, { useEffect } from "react";
import { connect } from "react-redux";
import {
  clearMerchantActionForm,
  clearMerchantRespForm,
  HandleChangeView,
  HandleMerchantTicketInput,
  HandleMerchantTicketRespDelete,
  HandleSubmitMerchantTicketAction,
  HandleSubmitMerchantTicketResp,
  /*HandleTicketFetchTypes,*/ HandleTicketResponse,
} from "../../../actions/merchant_support_ticket_action";
import user_image from "./../../../assets/img/user_image.svg";
import attach from "../../../assets/img/attach.svg";
import { Select, TextArea } from "../../../constants/constants";
import moment from "moment";
import ViewDocument from "./view_document";
import { decData } from "../../../constants";
import { Row, Col, Card, Button, Form } from "reactstrap";
const pointer = {
  cursor: "pointer",
};

export const SupportResponse = ({
  current_merchant_ticket_obj: ticket,
  ticket_types,
  resp_obj: respState,
  action_obj: actionState,
  ticket_response,
  dispatch
}) => {
  const [modal, setModal] = React.useState(false);
  const toggle = () => setModal(!modal);
  useEffect(() => {
    dispatch(HandleTicketResponse(ticket.id));
  }, [dispatch,ticket]);
  const merchantData = decData(sessionStorage.getItem("user"));
  const ticketType = ticket_types.length
    ? ticket_types.filter(
        (ticket_type) => ticket.ticket_type_id === ticket_type.id
      )[0]
    : {};
  const scroll = {
    overflowY: "auto",
  };
  let opts = ticket.possible_transitions.map((name, key) => {
    return { id: key, opt_name: name };
  });
  let last_message = ticket_response.length - 1;
  let older_msg = last_message - 3 > 0;
  const handleInput =(e) => {
    e.preventDefault();
    dispatch(HandleMerchantTicketInput(e));
  }

  return (
    <>
      {/* <div className="col-md-10 col-sm-12 col-lg-10"> */}
      <Row>
        <Col md={7} lg={7}>
          <Card>
            {/* <span className="title-text ml-2">{ticket.ticket_type.ticket_type_name}</span> */}
            <Col lg={12} sm={12} md={12} className="m-1">
              <img alt="" width="20px" height="20px" src={user_image} />
              <span className="ticket-header-text ms-1">
                {`${merchantData.firstname}`} ({`${merchantData.email}`}) Opened
                this ticket on {ticket.date_created}{" "}
              </span>
            </Col>
            <hr />
            <Col lg={12} sm={12} md={12} className="m-1">
              <img alt="" width="20px" height="20px" src={user_image} />
              <span className="ticket-detail-text-2 ">
                {" "}
                {`${merchantData.firstname}`}
              </span>
              <span className="ticket-detail-text-1">
                ({`${merchantData.email}`})
              </span>
              <span className="ticket-detail-text-3"> Wrote:</span>
            </Col>
            <span className="ticket-detail-text-4 pl-5">
              <b>{ticket.description}</b>
            </span>
            <Col lg={12} sm={12} md={12} className="m-1">
              <img width="20px" height="20px" src={user_image} alt="user" />
              <span className="ticket-detail-text-5"> You</span>
              <span className="ticket-detail-text-3"> wrote:</span>
            </Col>
            {ticket_response ? (
              <div style={scroll}>
                {older_msg && (
                  <small
                    data-target="#more"
                    data-toggle="collapse"
                    style={pointer}
                    className="text-info pl-3"
                  >
                    {" "}
                    Toggle older messages...
                  </small>
                )}
                <div id="more" className="collapse">
                  {ticket_response.map((resp, key) => {
                    if (key < last_message - 4) {
                      return (
                        <>
                          <span
                            style={pointer}
                            data-target={`#delete${key}`}
                            data-toggle="collapse"
                            className="  ticket-detail-text-4 pl-5 py-1 row "
                          >
                            {resp.comment_text}
                            <b className="text-info pl-1">
                              {moment(resp.date_created)
                                .startOf("hour")
                                .fromNow()}
                            </b>
                          </span>
                          <div id={`delete${key}`} className="collapse">
                            <b
                              style={pointer}
                              onClick={(e) => {
                                e.preventDefault();
                                dispatch(HandleMerchantTicketRespDelete(resp.id));
                              }}
                              className="ms-5"
                            >
                              <small className="text-danger">Delete</small>
                            </b>
                          </div>
                        </>
                      );
                    }
                    return null;
                  })}
                </div>
                {ticket_response.map((response, key) => {
                  if (key === last_message) {
                    return (
                      <>
                        <span
                          style={pointer}
                          data-target={`#delete${key}`}
                          data-toggle="collapse"
                          className="  ticket-detail-text-4 pl-5 py-1 row"
                        >
                          <b>{response.comment_text}</b>{" "}
                          <b className="text-info pl-1">
                            {moment(response.date_created).format(
                              "Do MMM, h:mm a"
                            )}
                          </b>
                        </span>
                        <div id={`delete${key}`} className="collapse">
                          <b
                            style={pointer}
                            onClick={(e) => {
                              e.preventDefault();
                              dispatch(HandleMerchantTicketRespDelete(response.id));
                            }}
                            className="ms-5"
                          >
                            <small className="text-danger">Delete</small>
                          </b>
                        </div>
                      </>
                    );
                  }
                  if (key > last_message - 4) {
                    return (
                      <>
                        <span
                          style={pointer}
                          data-target={`#delete${key}`}
                          data-toggle="collapse"
                          className=" ticket-detail-text-4 pl-5 py-1 row "
                        >
                          {response.comment_text}
                          <b className="text-info pl-1">
                            {moment(response.date_created).format(
                              "Do MMM, h:mm a"
                            )}
                          </b>
                        </span>
                        <div id={`delete${key}`} className="collapse">
                          <b
                            style={pointer}
                            onClick={(e) => {
                              e.preventDefault();
                              dispatch(HandleMerchantTicketRespDelete(response.id));
                            }}
                            className="ms-5"
                          >
                            <small className="text-danger">Delete</small>
                          </b>
                        </div>
                      </>
                    );
                  }
                  return null;
                })}
              </div>
            ) : (
              "Nothing yet"
            )}
            <Form
              autoComplete="off"
              onSubmit={(e) => {
                e.preventDefault();
                dispatch(HandleSubmitMerchantTicketResp(respState, ticket.id));
                dispatch(clearMerchantRespForm(e));
              }}
            >
              <Col lg={12} sm={12} md={12} className="m-1">
                <TextArea
                  name="comment_text"
                  className="form-control"
                  placeholder="Reply..."
                  value={respState.comment_text}
                  handleChange={handleInput}
                />
              </Col>
              <Col lg={12} sm={12} md={12} className="my-2">
                <img width="20px" height="20px" src={attach} alt="failed" />
                <span className="ticket-detail-text-1"> Attach files</span>
              </Col>
              <Col lg={12} sm={12} md={12} className="my-2">
                <Button type="submit" className="btn pay-btn">
                  <span className="pay-btn-text">Reply</span>
                </Button>
              </Col>
            </Form>
          </Card>
        </Col>
        <Col lg={5} md={5}>
          <Card>
            <Row>
              <Col lg={12} sm={12} md={12}>
                <span className="title-text">Ticket info</span>
              </Col>
              <Col lg={12} sm={12} md={12} className="my-2">
                <span>Assigned Agent:</span>
                <img width="20px" height="20px" src={user_image} alt="user" />
                <span className="title-text">{`${merchantData.firstname}`}</span>
              </Col>
              <Col lg={12} sm={12} md={12} className="my-2">
                <span>Ticket Reference:</span>
                <span className="title-text">{`SP ${merchantData.id}-${
                  typeof ticket.ticket_type === "undefined"
                    ? ticketType.id
                    : ticket.ticket_type.id
                }-${ticket.id}`}</span>
              </Col>
              {ticket.file_path && (
                <Col lg={12} md={12} sm={12} className="my-2">
                  <span>Uploads:</span>
                  <a
                    href={`blob:${ticket.file_path}`}
                    data-toggle="modal"
                    data-target="#viewDoc"
                    className="ticket-detail-text-5"
                  >
                    Document
                  </a>
                </Col>
              )}
              <Col lg={12} sm={12} md={12} className="my-2">
                <span>Category:</span>
                <span className="title-text">
                  {typeof ticket.ticket_type === "undefined"
                    ? ticketType.ticket_type_name
                    : ticket.ticket_type.ticket_type_name}
                </span>
              </Col>
              <Col lg={12} sm={12} md={12} className="my-2">
                <span>Status:</span>
                <span className="title-text">{ticket.status_text}</span>
              </Col>
              {(ticket.status_text !== "Canceled" ||
                ticket.status_text !== "Resolved") && (
                <Form
                  autoComplete="off"
                  onSubmit={(e) => {
                    e.preventDefault();
                    dispatch(HandleSubmitMerchantTicketAction(
                      actionState,
                      ticket.id,
                      merchantData.id
                    ));
                    dispatch(clearMerchantActionForm(e));
                  }}
                >
                  <Col lg={12} sm={12} md={12} className="my-2">
                    <Select
                      placeholder="select ticket action"
                      name="ticket_action"
                      index_key="opt_name"
                      label_key="opt_name"
                      value={actionState.action_id}
                      options={opts}
                      handleChange={handleInput}
                    />
                  </Col>
                  <Col lg={12} sm={12} md={12} className="my-2">
                    <TextArea
                      name="ticket_comment"
                      placeholder="Comment"
                      value={actionState.comment_text}
                      handleChange={handleInput}
                    />
                  </Col>
                  <Button type="submit" className="pay-btn m-2 text-white">
                    <span className="clear-close-text">Take Action</span>
                  </Button>
                </Form>
              )}
            </Row>
          </Card>
          <Button
            type="button"
            onClick={() => {
              dispatch(HandleChangeView(false));
            }}
            className="btn-outline-orange"
          >
            <span className="clear-close-text">Back to table</span>
          </Button>
        </Col>
      </Row>
      <ViewDocument {...{modal,toggle,ticket}} />
    </>
  );
};

const mapMerchantTicketResponseStateToProps = (state) => {
  return {
    current_merchant_ticket_obj:
      state.merchantTicketState.current_merchant_ticket_obj,
    resp_obj: state.merchantTicketState.resp_obj,
    action_obj: state.merchantTicketState.action_obj,
    ticket_response: state.merchantTicketState.ticket_response,
    ticket_types: state.merchantTicketState.ticket_types,
  };
};
export const ReduxTicketSupport = connect( mapMerchantTicketResponseStateToProps )(SupportResponse);
