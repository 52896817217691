const CryptoJS = require("crypto-js");

function makeid(length) {
  var result = "";
  var characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

const pass = "fa05daa0385e0995b7c1b94e942c1cb0";
// ency
export const encData = (data) => {
  if (data) {
    const d = btoa(JSON.stringify(data));
    var encyData = makeid(100) + CryptoJS.AES.encrypt(d, pass);
    return encyData;
  }
};
export const decData = (data) => {
  if (data) {
    const decryptData = CryptoJS.AES.decrypt(data.slice(100), pass);
    const dataDec = atob(decryptData.toString(CryptoJS.enc.Utf8));
    return JSON.parse(dataDec);
  }
};

//Server calls
export const AxiosHeaders = {
  "Content-Type": "application/json",
  "Access-Control-Allow-Origin": "*",
  //  "Authorization": `Bearer ${decData(sessionStorage.getItem('token'))}`
};
export const getCookieItem = (key) => {
  var cookieArr = document.cookie.split(";");
  for (var i = 0; i < cookieArr.length; i++) {
    var cookiePair = cookieArr[i].split("=");

    /* Removing whitespace at the beginning of the cookie name
      and compare it with the given string */
    if (key === cookiePair[0].trim()) {
      // Decode the cookie value and return
      return decodeURIComponent(cookiePair[1]);
    }
  }

  // Return null if not found
  return null;
};
export function deleteCookies() {
  var allCookies = document.cookie.split(";");

  for (var i = 0; i < allCookies.length; i++)
    document.cookie = allCookies[i] + "=;expires=" + new Date(0).toUTCString();
  window.location.reload();
}

export const clearCookies = (key) => {
  document.cookie = `${key}=;max-age=0`;
};
export const KEY = {
  backspace: 8,
  left: 37,
  right: 39,
};

export const validateInput = (formstate) => {
  let keys = Object.keys(formstate);
  let emptyFields = keys.filter((key) => {
    return !formstate[key] && key !== "id";
  });
  if (emptyFields.length) {
    // toastr.warning("Oops!", "You have some empty feilds");
  }
  return emptyFields;
};

export const invalidInput = {
  borderColor: "red",
};
