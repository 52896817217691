import React from "react";
import moment from "moment";
import { Row, Col, Input } from "reactstrap";

export const Reports = () => {
  return (
    <>
      <div className="pb-5">
        <Row>
          <span className="doc-header col-9">Company Documents</span>
          <Col lg={3} sm={12} md={3}>
            <Input
              type="text"
              name
              placeholder="Search Pdfs..."
              className="form-control"
            />
          </Col>
          <hr />
        </Row>
        <Row>
          <Col lg={12} sm={12} md={12}>
            <Row>
              <Col lg={3} sm={12} md={3}>
                <img
                  src="./images/pdf.svg"
                  height="100px"
                  width="100px"
                  alt=""
                />
                <span className="caption-text row ms-1">
                  {moment().format("D/M/YYYY")} -
                  {moment().add(1, "w").format("D/M/YYYY")}
                </span>
              </Col>
              <Col lg={3} sm={12} md={3}>
                <img
                  src="./images/pdf.svg"
                  height="100px"
                  width="100px"
                  alt=""
                />
                <span className="caption-text row ms-1">
                  {moment().format("D/M/YYYY")} to
                  {moment().add(1, "w").format("D/M/YYYY")}
                </span>
              </Col>
              <Col lg={3} sm={12} md={3}>
                <img
                  src="./images/pdf.svg"
                  height="100px"
                  width="100px"
                  alt=""
                />
                <span className="caption-text row ms-1">
                  {moment().format("D/M/YYYY")} to
                  {moment().add(1, "w").format("D/M/YYYY")}
                </span>
              </Col>
              <Col lg={3} sm={12} md={3}>
                <img
                  src="./images/pdf.svg"
                  height="100px"
                  width="100px"
                  alt=""
                />
                <span className="caption-text row ms-1">
                  {moment().format("D/M/YYYY")} to
                  {moment().add(1, "w").format("D/M/YYYY")}
                </span>
              </Col>
              <Col lg={3} sm={12} md={3}>
                <img
                  src="./images/pdf.svg"
                  height="100px"
                  width="100px"
                  alt=""
                />
                <span className="caption-text row ms-1">
                  {moment().format("D/M/YYYY")} to
                  {moment().add(1, "w").format("D/M/YYYY")}
                </span>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
      <div className="pt-5">
        <Row>
          <span className="doc-header col-9">Graph Reports</span>
          <Col lg={3} sm={12} md={3}>
            <Input
              type="text"
              name
              placeholder="Search Graphs..."
              className="form-control"
            />
          </Col>
          <Col sm={12} lg={12} md={12}>
            <hr />
          </Col>
        </Row>
        <Row>
          <Col lg={12} sm={12} md={12}>
            <Row>
              <Col lg={3} sm={12} md={3}>
                <img
                  src="./images/graph.png"
                  height="100px"
                  width="100px"
                  alt=""
                />
                <span className="caption-text row ms-1">
                  {moment().format("D/M/YYYY")}-
                  {moment().add(1, "w").format("D/M/YYYY")}
                </span>
              </Col>
              <Col lg={3} sm={12} md={3}>
                <img
                  src="./images/graph.png"
                  height="100px"
                  width="100px"
                  alt=""
                />
                <span className="caption-text row ms-1">
                  {moment().format("D/M/YYYY")} to
                  {moment().add(1, "w").format("D/M/YYYY")}
                </span>
              </Col>
              <Col lg={3} sm={12} md={3}>
                <img
                  src="./images/graph.png"
                  height="100px"
                  width="100px"
                  alt=""
                />
                <span className="caption-text row ms-1">
                  {moment().format("D/M/YYYY")} to
                  {moment().add(1, "w").format("D/M/YYYY")}
                </span>
              </Col>
              <Col lg={3} sm={12} md={3}>
                <img
                  src="./images/graph.png"
                  height="100px"
                  width="100px"
                  alt=""
                />
                <span className="caption-text row ms-1">
                  {moment().format("D/M/YYYY")} to
                  {moment().add(1, "w").format("D/M/YYYY")}
                </span>
              </Col>
              <Col lg={3} sm={12} md={3}>
                <img
                  src="./images/graph.png"
                  height="100px"
                  width="100px"
                  alt=""
                />
                <span className="caption-text row ms-1">
                  {moment().format("D/M/YYYY")} to
                  {moment().add(1, "w").format("D/M/YYYY")}
                </span>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
      <div className="pt-5">
        <Row>
          <span className="doc-header col-9">Excel reports</span>
          <Col lg={3} sm={12} md={3}>
            <Input
              type="text"
              name
              placeholder="Search Excel..."
              className="form-control"
            />
          </Col>
          <hr className="col-12" />
        </Row>
        <Row>
          <Col lg={12} sm={12} md={12}>
            <Row>
              <Col lg={3} sm={12} md={3}>
                <img
                  src="./images/excel.svg"
                  height="100px"
                  width="100px"
                  alt=""
                />
                <span className="caption-text row ms-1">
                  {moment().format("D/M/YYYY")}-
                  {moment().add(1, "w").format("D/M/YYYY")}
                </span>
              </Col>
              <Col lg={3} md={3} sm={12}>
                <img
                  src="./images/excel.svg"
                  height="100px"
                  width="100px"
                  alt=""
                />
                <span className="caption-text row ms-1">
                  {moment().format("D/M/YYYY")} to
                  {moment().add(1, "w").format("D/M/YYYY")}
                </span>
              </Col>
              <Col lg={3} sm={12} md={3}>
                <img
                  src="./images/excel.svg"
                  height="100px"
                  width="100px"
                  alt=""
                />
                <span className="caption-text row ms-1">
                  {moment().format("D/M/YYYY")} to
                  {moment().add(1, "w").format("D/M/YYYY")}
                </span>
              </Col>
              <Col lg={3} sm={12} md={3}>
                <img
                  src="./images/excel.svg"
                  height="100px"
                  width="100px"
                  alt=""
                />
                <span className="caption-text row ms-1">
                  {moment().format("D/M/YYYY")} to
                  {moment().add(1, "w").format("D/M/YYYY")}
                </span>
              </Col>
              <Col lg={3} sm={12} md={3}>
                <img
                  src="./images/excel.svg"
                  height="100px"
                  width="100px"
                  alt=""
                />
                <span className="caption-text row ms-1">
                  {moment().format("D/M/YYYY")} to
                  {moment().add(1, "w").format("D/M/YYYY")}
                </span>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    </>
  );
};
