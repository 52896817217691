import React from "react";
import TblComp from "../../../datatable";
import { currFormat } from "../../../constants/GenHelpers";

export const SingleReqTable = (props) => {
  return (
    <TblComp
      columns={[
        {
          Header: "Ref No",
          accessor: "ext_ref",
          filter: "fuzzyText",
        },
        {
          Header: "Amount",
          accessor: "amount",
          filter: "fuzzyText",
          Cell: ({ value }) => (value ? currFormat(value * 1) : ""),
        },
        {
          Header: "Customer Names",
          accessor: "customer_names",
          filter: "fuzzyText",
        },
        {
          Header: "Customer Email",
          accessor: "customer_email",
          filter: "fuzzyText",
        },
        {
          Header: "Transaction Note",
          accessor: "transaction_note",
          filter: "fuzzyText",
        },
      ]}
      {...props}
    />
  );
};
