import {
  Row,
  Col,
  ModalHeader,
  Modal,
  ModalBody,
  FormGroup,
  ModalFooter,
  Button,
} from "reactstrap";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { TextField } from "../../../auth/TextField";
import * as Yup from "yup";
import React, { useState } from "react";
import { useDispatch,shallowEqual,useSelector } from "react-redux";
import OTPComponent from "../../../auth/OTP/input";
import Stepper from "../../../../constants/stepper";
import {
  HandleLiquidationSubmit,
  HandleClose,
  VerifyLiquidationOtp,
} from "../../../../actions/liquidation_actions";
import { currFormat } from "../../../../constants/GenHelpers";

export const AddLiquidation = ({ toggle, modal }) => {
  const dispatch = useDispatch();
  const [validateAfterSubmit, setValidateAfterSubmit] = useState(false);

  const {is_loading:isProcessing, step, liquidation_response:liquidationResponse} = useSelector(
    (state) => state.LiquidationState
  );
  const user_obj = useSelector((state) => state.authState.user_obj,shallowEqual);

  const single_record = useSelector( (state) => state.companyState.single_record );

  const [code, setCode] = useState("");
  const validate = Yup.object({
    debit: Yup.string().required("Amount is required"),
    transaction_note: Yup.string().required("Narration is required"),
  });

  const closeForm = () => {
    toggle();
    HandleClose();
  }

  const handleChange = (otpCode) => {
    setCode(otpCode);
  };

  return (
    <>
      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>
          <span className="modal-header-text">Withdraw From Wallet</span>
        </ModalHeader>
        <ModalBody>
          <Stepper currentStep={step} />
          {step === 1 && (
            <Formik
              initialValues={{
                debit: "",
                transaction_note: "",
              }}
              validationSchema={validate}
              validateOnChange={validateAfterSubmit}
              validateOnBlur={true}
              onSubmit={(values, { setErrors, setSubmitting }) => {
                setValidateAfterSubmit(true);
                dispatch(
                  HandleLiquidationSubmit(
                    values,
                    user_obj.active_company.default_company_account.id,
                    setErrors,
                    setSubmitting
                  )
                );
              }}
            >
              {(formik) => (
                <Form>
                  <div className="container-fluid">
                    <div className="panel-body">
                      <Row className="mt-2 mb-3">
                        <Col lg={12} sm={12} md={12} className="text-center">
                          <p className="curr-bal">Wallet Balance:  
                          <span className="wallet_balance"> UGX{" "}
                            {typeof single_record.acc_balance !== "undefined" &&
                              currFormat(Math.round(single_record.acc_balance))}
                          </span>
                          </p>
                        </Col>
                      </Row>
                    </div>
                    <FormGroup className="mt-3">
                      <Row>
                        <Col lg={12} md={12} sm={12} className="my-2">
                          <TextField
                            name="debit"
                            type="text"
                            placeholder="Amount"
                          />
                        </Col>
                        <Col lg={12} md={12} sm="12" className="my-2">
                          <Field
                            as="textarea"
                            name="transaction_note"
                            placeholder="Narration"
                            className={`custom-select form-control ${
                              formik.errors.transaction_note
                                ? `is-invalid mb-2`
                                : ""
                            }`}
                          ></Field>
                          <ErrorMessage
                            component="div"
                            name="transaction_note"
                            className="error"
                          />
                        </Col>
                      </Row>
                    </FormGroup>
                  </div>

                  <ModalFooter className="modal-footer">
                    <Button type="submit" className="pay-btn text-white" block>
                      <span className="pay-btn-text ">
                        {isProcessing ? "Processing..." : "Next"}{" "}
                      </span>
                    </Button>
                  </ModalFooter>
                </Form>
              )}
            </Formik>
          )}
          {step === 2 && (
            <Formik
              initialValues={{
                txtRef: liquidationResponse.request_ref,
              }}
              onSubmit={(values) => {
                dispatch(VerifyLiquidationOtp(values.txtRef, code));
              }}
            >
              {(formik) => (
                <Form>
                  <div className="container-fluid">
                    <FormGroup className="mt-3">
                      <Row>
                        <div className="ml-5">
                          <p className="ml-2">
                            Enter the verification code sent to your email/phone
                          </p>
                          <div className="otp-custom mt-3">
                            <OTPComponent
                              onChange={handleChange}
                              value={code}
                            />
                          </div>
                        </div>
                      </Row>
                    </FormGroup>
                  </div>

                  <ModalFooter>
                    <Button
                      type="submit"
                      className="pay-btn text-white"
                      block
                      disabled={code.length === 6 ? false : true}
                    >
                      <span className="pay-btn-text ">
                        {isProcessing ? "Processing..." : "Verify"}{" "}
                      </span>
                    </Button>
                  </ModalFooter>
                </Form>
              )}
            </Formik>
          )}
          {step === 3 && (
            <div className="container-fluid">
              <Row>
                <p className="mx-2">
                  Your request for withrawal of funds has been received by an
                  administrator.{" "}
                </p>
                <p className="mx-2">
                  The administrator will review and respond as soon as possible.
                </p>
                <p className="mx-2">
                  You will be notified by email, of the final outcome.
                </p>
                <p className="mx-2">Thank you.</p>
              </Row>
            </div>
          )}
        </ModalBody>
        {step === 3 && (
          <ModalFooter>
            <Button
              className="pay-btn text-white"
              block
              onClick={() => dispatch(closeForm())}
            >
              <span className="pay-btn-text ">Close</span>
            </Button>
          </ModalFooter>
        )}
      </Modal>
    </>
  );
};
