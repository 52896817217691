import Axios from "axios";
// import { toastr } from "react-redux-toastr";
import { AxiosHeaders } from "../constants";
import { base_url } from "../constants/constants";

export const HandleCompanyContactInput = (e) => {
  const { value, name } = e.target ? e.target : e;
  return {
    type: "COMPANY_CONTACT_INPUT_CHANGE",
    changedField: { [name]: value },
  };
};

export const clearCompanyContactForm = (e) => {
  e.preventDefault();
  return { type: "CLEAR_COMPANY_CONTACT_FORM" };
};

export const CompanyContactFetchSuccess = (payload, msg) => {
  return { type: "FETCH_COMPANY_CONTACT_SUCCESS", payload };
};
export const CompanyContactAddSuccess = (new_record) => {
  // toastr.success("Kudos!", "Company Contact Addded...");
  return { type: "ADD_COMPANY_CONTACT_SUCCESS", new_record };
};
export const CompanyContactDeleteSuccess = (msg, record_id) => {
  // Print the message here
  // toastr.success("Ok!", msg);
  return { type: "DELETE_COMPANY_CONTACT_SUCCESS", record_id };
};
export const CompanyContactReqError = (message) => {
  return { type: "COMPANY_CONTACT_ERROR" };
};

export const editCompanyContact = (obj) => {
  return { type: "EDIT_COMPANY_CONTACT", current_obj: obj };
};
export const HandleCompanyContactSubmit = (form_data, url_id) => (dispatch) => {
  // console.log(url_id)
  const url = form_data.id
    ? base_url("contact/" + form_data.id)
    : base_url("company/" + url_id + "/contact");
  var name = form_data.masked_contact.replace(/\D+/g, "");
  const req_type = form_data.id ? Axios.put : Axios.post;
  req_type(
    url,
    { ...form_data, contact_details: "+" + name },
    { headers: AxiosHeaders }
  )
    .then((response) => {
      if (response.status === 201 || response.status === 200) {
        dispatch(CompanyContactAddSuccess(response.data));
      }
    })
    .catch((failure_reason) => {
      dispatch(CompanyContactReqError("Network/Server error"));
      console.error(failure_reason);
    });
};
export const HandleCompanyContactDelete = (record_id) => (dispatch) => {
  const url = base_url("contact/" + record_id);
  Axios.delete(url, { headers: AxiosHeaders })
    .then((response) => {
      if (response.status === 201 || response.status === 200) {
        dispatch(CompanyContactDeleteSuccess(response.data.message, record_id));
      }
    })
    .catch((failure_reason) => {
      dispatch(CompanyContactReqError("Network/Server error"));
      console.error(failure_reason);
    });
};
export const HandleCompanyContactFetch = (url_id) => (dispatch) => {
  const url = base_url("company/" + url_id + "/contact");
  Axios.get(url, { headers: AxiosHeaders })
    .then((response) => {
      if (response.status === 200) {
        dispatch(CompanyContactFetchSuccess(response.data));
      }
    })
    .catch((failure_reason) => {
      dispatch(CompanyContactReqError("Network/Server error"));
      console.error(failure_reason);
    });
};
