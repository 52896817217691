import React, { useEffect, useState } from "react";
import TblComp from "../../datatable";
import { connect } from "react-redux";
import { HandlePaymentFetch } from "../../actions/payment_actions";
import { HandleCompanyFetch } from "../../actions/companyActions";
import DashDonut from "../../components/app/charts/donutChart";
import DashBarChart from "../../components/app/charts/barChart";
import { useSelector } from "react-redux";
import { HandleTransactionsFetch } from "../../actions/payment_actions";
import DashLineChart from "../../components/app/charts/lineChart";
import { checkAuthentication } from "../../actions/authAction";
import moment from "moment";
import { currFormat } from "../../constants/GenHelpers";
import { Input, Label } from "reactstrap";
import { AddLiquidation } from "../../components/app/products/liquidations/add_liquidation";
import {
  Button,
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Badge,
} from "reactstrap";

function Dashboard({payment_list,dispatch,session_data,single_record}) {
  const [single, setSingle] = useState();
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);
  const [paymentModal, setPaymentModal] = useState(false);
  const togglePayment = () => setPaymentModal(!paymentModal);
  const [modalLiq, setmodalLiq] = useState(false);
  const toggleLiq = () => setmodalLiq(!modalLiq);
  const [ModalDetails, setModalDetails] = useState(false);
  const toggleDetails = () => setModalDetails(!ModalDetails);

  useEffect(() => {
    dispatch(checkAuthentication());
  }, [dispatch]);
  
  useEffect(() => {
    session_data.active_company != null && Object.keys(single_record).length === 0 &&
      dispatch(HandleCompanyFetch(session_data.active_company.id, true));
  }, [dispatch,session_data.active_company,single_record]);

  useEffect(() => {
    payment_list.length === 0 &&
      session_data.active_company != null &&
      dispatch(HandlePaymentFetch(session_data.active_company.id));
    }, [dispatch,payment_list.length,session_data.active_company]);

    useEffect(() => {
    dispatch(HandleTransactionsFetch(session_data.active_company.id));
  }, [dispatch,session_data.active_company.id]);

  const totalCollections = useSelector(
    (state) => state.collectionState.totalAggregate
  );

  const totalPayments = useSelector(
    (state) => state.paymentState.totalAggregate
  );

  return (
    <>
      <AddLiquidation toggle={toggleLiq} modal={modalLiq} />
      <Modal isOpen={ModalDetails} toggle={toggleDetails}>
        <ModalBody>
          <Col lg={6} sm={12} md={6} className="font-weight-bold">
            Reference Number
          </Col>
          <Col lg={6} sm={12} md={6}>
            {typeof single !== "undefined" ? single.request_ref : "Loading..."}
          </Col>
          <hr />
          <Col lg={6} sm={12} md={6} className="my-2 font-weight-bold ">
            Amount
          </Col>
          <Col lg={6} sm={12} md={6} className="my-2">
            {typeof single !== "undefined"
              ? currFormat(single.debit * 1)
              : "Loading.."}
          </Col>
          <hr />
          <Col lg={6} sm={12} md={12} className="my-2 font-weight-bold ">
            Status
          </Col>
          <Col lg={6} sm={12} md={13} className="my-2">
            <b>
              {typeof single !== "undefined"
                ? single.status_text
                : "Loading..."}
            </b>
          </Col>
          <hr />
          <Col lg={6} sm={12} md={6} className="my-2 font-weight-bold ">
            Currency
          </Col>
          <Col lg={6} sm={12} md={6} className="my-2">
            {typeof single !== "undefined" ? single.status_text : "Loading..."}
          </Col>
          <hr />
          <Col lg={6} sm={12} md={6} className="my-2 font-weight-bold ">
            Provider
          </Col>
          <Col lg={6} sm={12} md={6} className="my-2">
            {typeof single !== "undefined"
              ? typeof single.sp_country !== "undefined" &&
                single.sp_country &&
                single.sp_country.spc_code
              : "Loading..."}
          </Col>
          <hr />
          <Col lg={6} sm={12} md={6} className="my-2 font-weight-bold ">
            Transaction Date and Time
          </Col>
          <Col lg={6} sm={12} md={6} className="my-2">
            {typeof single !== "undefined"
              ? typeof single.transaction_date !== "undefined" &&
                moment(single.transaction_date).format("D-M-YYYY h:mm:ss a")
              : "Loading..."}
          </Col>
          <hr />
        </ModalBody>
      </Modal>

      <div className="content-box-header panel-heading table-container">
        <Row>
          <Col sm="12" lg="4" md="5">
            <div className="content-box-large p-4">
              <div className="panel-body">
                <Row className="mt-2 mb-3">
                  <Col lg={12} sm={12} md={12} className="text-center">
                    <p className="curr-bal">Wallet balance</p>
                    <span className="ugx-3300">
                      UGX{" "}
                      {typeof single_record.acc_balance !== "undefined" &&
                        currFormat(Math.round(single_record.acc_balance))}
                    </span>
                  </Col>
                </Row>
                <Row className="mt-2 mb-3">
                  <Col lg={6} sm={12} md={6} className="text-center">
                    <h6 className="pay_col_bal">Collections</h6>
                    <span className="ugx-currency me-2">UGX</span>
                    <span className="ugx-amount">
                      {currFormat(Math.round(totalCollections))}
                    </span>
                  </Col>
                  <Col lg={6} sm={12} md={6} className="text-center">
                    <h6 className="pay_col_bal">Payments</h6>
                    <span className="ugx-currency me-2">UGX</span>
                    <span className="ugx-amount">
                      {currFormat(Math.round(totalPayments))}
                    </span>
                  </Col>
                </Row>
                <div className="text-center">
                  <Button onClick={(e) => toggleLiq()} className="withdraw-btn">
                    <span className="withdraw-btn-text">Withdraw</span>
                  </Button>
                </div>
              </div>
            </div>
          </Col>
          <Col sm="12" lg="8" md="7">
            <DashDonut
              collctn={Math.round(totalCollections)}
              payment={Math.round(totalPayments)}
            />
          </Col>
        </Row>
      </div>
      <Row className="mb-4 mt-5">
        <Col md={4} sm={12} lg={6}>
          <div className="content-box-header panel-heading table-container">
            <DashLineChart />
          </div>
        </Col>
        <Col md={8} sm={12} lg={6}>
          <div className="content-box-header panel-heading table-container">
            <DashBarChart />
          </div>
        </Col>
      </Row>
      <div className="content-box-header panel-heading table-container mb-2">
        {typeof payment_list !== "undefined" ? (
          <>
            <Button className="payment-btn my-3 text-white">
              Recent Payments
            </Button>
            <TblComp
              columns={[
                {
                  id: "transaction_date",
                  Header: "Date",
                  accessor: "transaction_date",
                  filter: "fuzzyText",
                  Cell: ({ value }) =>
                    value ? moment(value).format("D-M-YYYY h:mm:ss a") : "",
                },
                {
                  Header: "Ref No.",
                  maxWidth: 200,
                  accessor: "request_ref",
                  filter: "fuzzyText",
                },
                {
                  id: "debit",
                  Header: "Amount",
                  accessor: "debit",
                  filter: "fuzzyText",
                  Cell: ({ value }) => currFormat(value ? value * 1 : 0),
                },
                {
                  id: "charges",
                  Header: "Charge",
                  accessor: "charges",
                  filter: "fuzzyText",
                  Cell: ({ value }) => currFormat(value ? value * 1 : 0),
                },
                {
                  id: "balance",
                  Header: "Balance",
                  accessor: "balance",
                  Cell: ({ value }) => (value ? currFormat(value * 1) : ""),
                },
                {
                  Header: "Phone",
                  accessor: "msisdn",
                  filter: "fuzzyText",
                },
                {
                  Header: "Status",
                  accessor: "status_text",
                  filter: "fuzzyText",
                },

                {
                  id: "actions", // Required because our accessor is not a string
                  Header: "Action",
                  maxWidth: 150,
                  accessor: (full) => (
                    <div className="d-flex">
                      <a
                        href
                        onClick={(e) => [
                          e.preventDefault(),
                          setSingle(full),
                          togglePayment(),
                        ]}
                      >
                        <Badge color="info">
                          <img
                            src="/images/view.svg"
                            height="15px"
                            width="15px"
                            alt="view"
                            className="ml-1"
                          />
                        </Badge>
                      </a>
                    </div>
                  ),
                },
              ]}
              data={payment_list}
            />
          </>
        ) : (
          <span>Fetching records...</span>
        )}
      </div>

      <Modal isOpen={false} toggle>
        <ModalHeader>
          <h4 className="modal-title-border font-weight-bold modal-header-txt ml-5 mt-2">
            Withdraw
          </h4>
        </ModalHeader>
        <ModalBody>
          <div className="m-1">
            <span className="report-header">Choose Report Type </span>
            <hr />
            <Row className="ml-1">
              <div className="input-checkbox-sm mt-1 mr-1">
                <Input
                  className="checkbox-c"
                  type="checkbox"
                  height="15px"
                  width="15px"
                />
              </div>
              <span className="graph-text-active">Graph</span>
              <div className="input-checkbox-sm mt-1 mr-1">
                <Input
                  className="checkbox-c"
                  type="checkbox"
                  height="15px"
                  width="15px"
                />
              </div>
              <span className="graph-text">Pie-Chart</span>
              <div className="input-checkbox-sm mt-1 mr-1">
                <Input
                  className="checkbox-c"
                  type="checkbox"
                  height="15px"
                  width="15px"
                />
              </div>
              <span className="graph-text">Listing</span>
            </Row>
          </div>
          <div className="mt-3">
            <span className="report-header">Choose Report Time Frame</span>
            <hr />
            <Row>
              <Row lg={6} md={6} sm={12}>
                <Label className="graph-text">From</Label>
                <Input
                  type="date"
                  name="report_time_from"
                  className="form-control"
                />
              </Row>
              <Row lg={6} md={6} sm={12}>
                <Label className="graph-text">To</Label>
                <Input
                  type="date"
                  name="report_time_to"
                  className="form-control"
                />
              </Row>
            </Row>
          </div>
          <div className="mt-3">
            <span className="report-header">Document Type</span>
            <hr />
            <Row className="ml-1">
              <div className="input-checkbox-sm mt-1 mr-1">
                <Input
                  className="checkbox-c"
                  type="checkbox"
                  height="15px"
                  width="15px"
                  name="pdf"
                  id="pdf_doc"
                />
              </div>
              <span className="graph-text-active">PDF</span>
              <div className="input-checkbox-sm mt-1 mr-1">
                <Input
                  className="checkbox-c"
                  type="checkbox"
                  height="15px"
                  width="15px"
                  name="doc_file"
                  id="docfile"
                />
              </div>
              <span className="graph-text">DOCX</span>
              <div className="input-checkbox-sm mt-1 mr-1">
                <Input
                  className="checkbox-c"
                  type="checkbox"
                  height="15px"
                  width="15px"
                  name="xls_file"
                  id="xlsfile"
                />
              </div>
              <span className="graph-text">EXCEL</span>
            </Row>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button className="pay-btn">
            <span className="pay-btn-text">Generate</span>
          </Button>
          <Button className="btn-outline-orange">
            <span className="clear-close-text m-1 ">Clear</span>
          </Button>
          <Button onClick={toggle} className="close-btn">
            <span className="clear-close-text">Close</span>
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
}

const mapDashboardSateToProps = (state) => {
  return {
    session_data: state.authState.user_obj,
    single_record: state.companyState.single_record,
    payment_list: state.paymentState.payment_list,
  };
};

const DashboardHome = connect(mapDashboardSateToProps)(Dashboard);
export default DashboardHome;
