const PaymentFormState = {
  id: "",
  amount: "",
  msisdn: "",
  transaction_note: "",
  masked_contact: "",
  currency_id: 1,
  code: "",
};
const PaymentInitState = {
  payment_list: [],
  transactionList: [],
  grouped_payment_list: [],
  initPayment: false,
  current_payment_obj: PaymentFormState,
  payment_state: "new_payment",
  otp_code_state:"verified",
  totalAggregate: 0,
  recentTransactions: [],
  status: "",
  isVerifying: false,
  total: 0,
  pageCount: 1,
  is_loading: false,
};

export const PaymentReducer = (prevState = PaymentInitState, action) => {
  const form_state = prevState.current_payment_obj;
  let new_formState;
  switch (action.type) {
    case "PAYMENT_ERROR":
      return { ...prevState };
    case "PAYMENT_RESPONSE":
      return { ...prevState, status: action.msg };
    case "SET_VERIFICATION_STATE":
      return { ...prevState, isVerifying: action.payload };
    case "SET_OTP_CODE_STATE":
      return {...prevState, otp_code_state: action.payload}
    case "SET_PAYMENT_STATE":
      return { ...prevState, payment_state: action.payload };
    case "IS_LOADING":
      return { ...prevState, is_loading: action.payload };
    case "FETCH_PAYMENT_SUCCESS":
      if (action.isPayment) {
        return { ...prevState, single_record: action.payload };
      } else {
        return {
          ...prevState,
          total: action.payload.total,
          payment_list: action.payload.data,
        };
      }
    case "FETCH_TRANSACTION_SUCCESS":
      const { total, last_page: pageCount } = action.payload;
      if (action.isTransaction) {
        return { ...prevState, single_record: action.payload };
      } else {
        return {
          ...prevState,
          total,
          pageCount,
          transactionList: action.payload.data,
        };
      }
    case "FETCH_GROUPED_PAYMENT_SUCCESS":
      return {
        ...prevState,
        grouped_payment_list: action.payload.data,
        totalAggregate: action.payload.total,
      };
    case "FETCH_RECENT_TRANS_SUCCESS":
      return {
        ...prevState,
        recentTransactions: action.payload.data,
      };

    case "PAYMENT_INPUT_CHANGE":
      new_formState = { ...form_state, ...action.changedField };
      return { ...prevState, current_payment_obj: new_formState };
    case "CLEAR_PAYMENT_FORM":
      return { ...prevState, current_payment_obj: PaymentFormState };
    case "EDIT_PAYMENT":
      new_formState = { ...action.current_payment_obj };
      return { ...prevState, current_payment_obj: new_formState };
    case "DELETE_PAYMENT_SUCCESS":
      let new_list = prevState.payment_list.filter(
        (current_rec) => current_rec.id !== action.record_id
      );
      return { ...prevState, payment_list: new_list };
    case "ADD_PAYMENT_SUCCESS":
      let payment_new = true;
      let transactionList = prevState.transactionList.map((payment) => {
        if (payment.id === action.new_record.id) {
          payment_new = false;
          return action.new_record;
        } else {
          return payment;
        }
      });
      payment_new && transactionList.push(action.new_record);
      const new_state = { ...prevState, transactionList };
      if (payment_new) {
        new_state.current_payment_obj = PaymentFormState;
      }
      return new_state;
    default:
      return prevState;
  }
};
