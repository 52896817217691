import React, { useState } from "react";
import { Link } from "react-router-dom";
import { base_url } from "../../constants/constants";
import Axios from "axios";
import { AxiosHeaders } from "../../constants";
import { Formik, Form } from "formik";
import { TextField } from "./TextField";
import * as Yup from "yup";
import MailSent from "./mailSent";
import AuthFooter from "./authFooter";
import { Row, Col, Button } from "reactstrap";
import WelcomeText from "./welcomeText";

const ForgotPass = (props) => {
  const validate = Yup.object({
    email: Yup.string().email("Email is invalid").required("Email is required"),
  });

  const [redirectUser, redirectTo] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [isProcessing, setProcessing] = useState(false);

  const HandleSubmit = (email) => {
    if (email) {
      setProcessing(true);
      const url = base_url("merchant/forgot-password");
      Axios.post(url, { email: email }, { headers: AxiosHeaders })
        .then((res) => {
          if (res.status === 200 || res.status === 500) {
            redirectTo(true);
            setProcessing(false);
          } else {
            redirectTo(false);
          }
        })
        .catch((err) => {
          setProcessing(false);
          if (err.response) {
            setErrorMessage(err.response.data.message);
            redirectTo(false);
          } else if (err.request) {
            // toastr.error("Bad/No internet connection");
            console.log(err.request);
          } else {
            console.log("Bad/No internet connection");
          }
        });
    } else {
      // toastr.error("oops", "Please provide your email address");
    }
  };

  return (
    <>
      <div
        className="login-body container-fluid"
        style={{ height: "100vh", overflowY: "hidden" }}
      >
        <Row>
          <Col lg={9} md={9} sm={12} className="mobile_view">
            <img
              width="800px"
              height="200px"
              src="./images/login-svg.png"
              alt=""
            />
          </Col>
          <Col lg={3} md={3} sm={12} className="float-end">
            <nav className="navbar navbar-expand-lg navbar-light">
              <Button
                className="navbar-toggler"
                type="button"
                data-toggle="collapse"
                data-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span className="navbar-toggler-icon" />
              </Button>
              <div
                className="collapse navbar-collapse"
                id="navbarSupportedContent"
              >
                <ul className="navbar-nav me-auto">
                  <li className="nav-item active">
                    <Link to="/sign_up" className="nav-link login-nav-btn">
                      Sign Up
                    </Link>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link login-nav-btn" href="/#">
                      FAQ
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link login-nav-btn" href="/#">
                      Help &amp; Contact
                    </a>
                  </li>
                  <li className="nav-item dropdown">
                    <a
                      className="nav-link dropdown-toggle login-nav-btn"
                      href="/#"
                      id="navbarDropdown"
                      role="button"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      ENG
                    </a>
                  </li>
                </ul>
              </div>
            </nav>
          </Col>
        </Row>
        <Row>
          <Col lg={7} md={6} sm={12} className="pt-5 px-5 text-center">
            <WelcomeText welcome="Welcome back to" />

            <h5 className="mx-auto text-center">Forgot Password</h5>
          </Col>

          <Col lg={5} md={5} sm={12}>
            {redirectUser === true ? (
              <MailSent type="reset" />
            ) : (
              <>
                <div
                  class="login-wrapper w-75 mx-auto"
                  style={{ display: errorMessage === null ? "none" : "" }}
                >
                  <i className="fa fa-times"></i>
                  <p className="text-danger">{errorMessage}</p>
                </div>
                <div className="login-wrapper w-75 mx-auto">
                  <div className="box">
                    <div className="content-wrap p-2">
                      <Formik
                        initialValues={{
                          email: "",
                        }}
                        validationSchema={validate}
                        onSubmit={(values) => {
                          HandleSubmit(values.email);
                        }}
                      >
                        {(formik) => (
                          <Form>
                            <h4 className="mb-2 pass-reset p-3 justify-content-center">
                              Please enter your email
                            </h4>
                            <span className="pass-reset-text">
                              Password reset link will be sent to your email.
                            </span>
                            <TextField
                              name="email"
                              type="email"
                              placeholder="example@sentepay.com"
                            />

                            <Button
                              className="sign-in-btn  my-1 shadow-none"
                              type="submit"
                              block
                            >
                              {isProcessing ? "Processing ..." : "Proceed"}
                            </Button>
                            <div className="text-center">
                              <div className="already">
                                <p className="sign-up-text">
                                  Don't have an account yet?
                                </p>
                                <Link to="/sign_up" className="sign-up-text">
                                  Get started
                                </Link>
                              </div>
                            </div>
                          </Form>
                        )}
                      </Formik>
                    </div>
                  </div>
                </div>
              </>
            )}
          </Col>
        </Row>
        <AuthFooter />
      </div>
    </>
  );
};

export default ForgotPass;
